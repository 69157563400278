import PropTypes from 'prop-types';
import { Box, CircularProgress, Typography, Button } from '@mui/material';
import { useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import {
  DndContext,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  useDroppable,
  DragOverlay,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { SortableItem } from './SortableItem';

const DroppableColumn = ({ id, items, children }) => {
  const { setNodeRef } = useDroppable({ id });

  return (
    <SortableContext
      id={id}
      items={items}
      strategy={verticalListSortingStrategy}
    >
      <Box ref={setNodeRef}>{children}</Box>
    </SortableContext>
  );
};

DroppableColumn.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
};

const defaultAnimateLayoutChanges = (args) => {
  const { isSorting, wasSorting } = args;
  return isSorting || wasSorting;
};

const WundaBoard = ({
  columns,
  columnStates,
  onLoadMore,
  loading,
  error,
  onCardClick,
  onColumnChange,
  renderCard,
}) => {
  const columnRefs = useRef({});
  const [activeId, setActiveId] = useState(null);
  const [activeItem, setActiveItem] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor)
  );

  const handleDragStart = (event) => {
    const { active } = event;
    setActiveId(active.id);

    // Find the dragged item
    for (const column of columns) {
      const columnState = columnStates[column.id];
      const item = columnState?.clients?.find((item) => item._id === active.id);
      if (item) {
        setActiveItem(item);
        break;
      }
    }
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;

    if (!active || !over) {
      setActiveId(null);
      setActiveItem(null);
      return;
    }

    const itemId = active.id;

    // Find source column and item
    let sourceColumn;
    let sourceItem;

    for (const column of columns) {
      const columnState = columnStates[column.id];
      const item = columnState?.clients?.find((item) => item._id === itemId);
      if (item) {
        sourceColumn = column;
        sourceItem = item;
        break;
      }
    }

    // Find target column - first check if we dropped directly on a column
    let targetColumn = columns.find((col) => col.id === over.id);

    // If not found, check if we dropped on a card and find its parent column
    if (!targetColumn) {
      for (const column of columns) {
        const columnState = columnStates[column.id];
        if (columnState?.clients?.some((item) => item._id === over.id)) {
          targetColumn = column;
          break;
        }
      }
    }

    setActiveId(null);
    setActiveItem(null);

    if (
      !sourceColumn ||
      !targetColumn ||
      !sourceItem ||
      sourceColumn.id === targetColumn.id
    )
      return;

    try {
      await onColumnChange(sourceItem, targetColumn);
    } catch (error) {
      console.error('Failed to update column:', error);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          opacity: 0.8,
        }}
      >
        <CircularProgress size={24} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          color: 'error.main',
        }}
      >
        <Typography>{error}</Typography>
      </Box>
    );
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          height: '100%',
          overflowX: 'auto',
          overflowY: 'hidden',
          px: 2,
          pb: 2,
        }}
      >
        {columns.map((column) => {
          const columnState = columnStates[column.id] || {
            clients: [],
            isLoading: false,
            hasNextPage: false,
            total: 0,
          };

          return (
            <Box
              key={column.id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: 280,
                flexShrink: 0,
                bgcolor: 'background.neutral',
                borderRadius: 2,
                overflow: 'hidden',
                height: '100%',
              }}
            >
              <Box
                sx={{
                  p: 2,
                  borderBottom: '1px solid',
                  borderColor: 'divider',
                  bgcolor: 'background.paper',
                }}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  {column.title}
                  {columnState.total > 0 && (
                    <Typography
                      component="span"
                      variant="caption"
                      sx={{ ml: 1, color: 'text.secondary' }}
                    >
                      ({columnState.total})
                    </Typography>
                  )}
                </Typography>
              </Box>

              <DroppableColumn
                id={column.id}
                items={columnState.clients?.map((item) => item._id) || []}
              >
                <Box
                  ref={(el) => (columnRefs.current[column.id] = el)}
                  sx={{
                    flex: 1,
                    overflowY: 'auto',
                    p: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    minHeight: 0,
                    height: '100%',
                    '&::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: 'background.neutral',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: 'grey.400',
                      borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                      backgroundColor: 'grey.500',
                    },
                  }}
                >
                  {columnState.clients?.map((item) => (
                    <SortableItem
                      key={`${column.id}-${item._id}`}
                      id={item._id}
                      onClick={() => onCardClick?.(item)}
                      animateLayoutChanges={defaultAnimateLayoutChanges}
                    >
                      {renderCard(item)}
                    </SortableItem>
                  ))}

                  {columnState.isLoading && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        py: 2,
                      }}
                    >
                      <CircularProgress size={20} />
                    </Box>
                  )}

                  {!columnState.isLoading &&
                    (!columnState.clients ||
                      columnState.clients.length === 0) && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          py: 4,
                          color: 'text.secondary',
                        }}
                      >
                        <Typography variant="body2">No items</Typography>
                      </Box>
                    )}

                  {!columnState.isLoading && columnState.hasNextPage && (
                    <Button
                      onClick={() => onLoadMore(column.id)}
                      startIcon={<Icon icon="mdi:plus" />}
                      sx={{
                        mt: 1,
                        color: 'text.secondary',
                        '&:hover': {
                          bgcolor: 'action.hover',
                        },
                      }}
                    >
                      Load more
                    </Button>
                  )}
                </Box>
              </DroppableColumn>
            </Box>
          );
        })}
      </Box>

      <DragOverlay dropAnimation={null}>
        {activeId && activeItem ? renderCard(activeItem) : null}
      </DragOverlay>
    </DndContext>
  );
};

WundaBoard.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  columnStates: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      clients: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string.isRequired,
        })
      ).isRequired,
      isLoading: PropTypes.bool,
      hasNextPage: PropTypes.bool,
      total: PropTypes.number,
    }),
  }).isRequired,
  onLoadMore: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string,
  onCardClick: PropTypes.func,
  onColumnChange: PropTypes.func.isRequired,
  renderCard: PropTypes.func.isRequired,
};

export default WundaBoard;
