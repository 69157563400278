import PropTypes from 'prop-types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box } from '@mui/material';

export function SortableItem({ id, children, onClick, animateLayoutChanges }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging: isBeingDragged,
    over,
  } = useSortable({
    id,
    animateLayoutChanges,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isBeingDragged ? 0.4 : 1,
    position: 'relative',
    cursor: 'grab',
    touchAction: 'none',
    backgroundColor: isBeingDragged ? 'rgba(63, 81, 181, 0.08)' : 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(63, 81, 181, 0.04)',
    },
    '&::before': isBeingDragged
      ? {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          border: '2px dashed #3f51b5',
          borderRadius: '4px',
          pointerEvents: 'none',
        }
      : undefined,
    '&::after': over
      ? {
          content: '""',
          position: 'absolute',
          left: 0,
          right: 0,
          height: '2px',
          backgroundColor: '#3f51b5',
          pointerEvents: 'none',
          top: over.id === id ? 'auto' : 0,
          bottom: over.id === id ? 0 : 'auto',
        }
      : undefined,
  };

  return (
    <Box
      ref={setNodeRef}
      sx={style}
      {...attributes}
      {...listeners}
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      {children}
    </Box>
  );
}

SortableItem.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  animateLayoutChanges: PropTypes.func,
};
