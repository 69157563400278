import React, { useEffect, useState, createContext } from 'react';
import PropTypes from 'prop-types';
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  updateProfile,
  signInWithPopup,
  signInWithRedirect,
  GoogleAuthProvider,
  getRedirectResult,
} from 'firebase/auth';
import GenericLoader from '../components/loader/GenericLoader';
import { apiRequest } from '../api/api';
import { logMessage } from '../utils/logger';
import { auth } from '../firebase/firebase';
import { getLocalStorageItem } from '../utils';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [redirectResult, setRedirectResult] = useState(null);
  const [advancedBalanceMode, setAdvancedBalanceMode] = useState(false); // shows transcription minutes and insight credits instead of session credits

  const userData = JSON.parse(localStorage.getItem('currentUser'));

  const saveUserDataToLocalStorage = (user) => {
    const userData = {
      _id: user.DBuser._id,
      name: user.DBuser?.name || user.name,
      email: user.DBuser?.email || user.email,
      preferences: user.DBuser.preferences,
      allowedThemes: user.DBuser.allowedThemes,
      defaultTheme: user.DBuser.defaultTheme,
      workspaceStatus: user.DBuser.workspaceStatus,
      workspaceInvitation: user.DBuser.workspaceInvitation,
      workspace: user.DBuser.workspace,
      workspaceRole: user.DBuser.workspaceRole,
    };

    localStorage.setItem('currentUser', JSON.stringify(userData));
    logMessage(
      'VERBOSE',
      `saveUserDataToLocalStorage(). User data saved to local storage.`
    );
  };

  const resetAuthInProgress = () => {
    localStorage.removeItem('googleAuthInProgress');
    localStorage.removeItem('emailAuthInProgress');
  };

  const updateUserData = (newData) => {
    // Merge new data with existing user data
    const updatedUser = {
      ...currentUser,
      DBuser: {
        ...currentUser.DBuser,
        ...newData.DBuser,
      },
    };

    // Update state
    setCurrentUser(updatedUser);

    // Update localStorage
    saveUserDataToLocalStorage(updatedUser);
  };

  const forceLogout = async () => {
    logMessage('VERBOSE', 'forceLogout(). Starting force logout process');
    try {
      logMessage(
        'VERBOSE',
        'forceLogout(). Removing user data from local storage'
      );
      window.localStorage.removeItem('currentUser'); // Remove user data from local storage

      logMessage('VERBOSE', 'forceLogout(). Signing out user from Firebase');
      await signOut(auth); // Sign out the user with Firebase

      logMessage(
        'VERBOSE',
        'forceLogout(). Resetting redirect result and current user states'
      );
      setRedirectResult(null);
      setCurrentUser(null); // Clear the current user from the context

      logMessage(
        'VERBOSE',
        'forceLogout(). Force logout completed successfully'
      );
    } catch (error) {
      logMessage(
        'ERROR',
        `forceLogout(). Error during force logout: ${error.message}`
      );
      throw error; // Re-throw the error to be handled by the caller
    }
  };

  useEffect(() => {
    const auth = getAuth();
    const googleAuthInProgress =
      localStorage.getItem('googleAuthInProgress') === 'true';
    const emailAuthInProgress =
      localStorage.getItem('emailAuthInProgress') === 'true';
    const authInProgress = googleAuthInProgress || emailAuthInProgress;
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user && !user.DBuser) {
        try {
          if (googleAuthInProgress) {
            const result = await getRedirectResult(auth);
            if (result) {
              setRedirectResult(result);
              logMessage(
                'VERBOSE',
                `AuthProvider(). Google auth in progress. Redirect result received.`
              );
            } else {
              logMessage(
                'DEBUG',
                `AuthProvider(). Google auth in progress. Redirect result is null. Logging off...`
              );
              setRedirectResult(null);
              await forceLogout();
            }
          } else if (!authInProgress) {
            logMessage(
              'VERBOSE',
              `AuthProvider(). Auth not in progress. Fetching user by firebase uid, and saving to local storage...`
            );
            user.DBuser = await getUserByFirebaseUid(user.uid);
            saveUserDataToLocalStorage(user);
          }

          // Check if reauthentication is needed
          if (
            user.DBuser &&
            user.DBuser.config &&
            user.DBuser.config.reauthenticate
          ) {
            logMessage(
              'debug',
              'AuthProvider(). Reauthentication required. Signing out user.'
            );
            await updateDBUser(user.DBuser._id, {
              config: {
                reauthenticate: false,
              },
            });
            await forceLogout();
            // After signing out, you may want to redirect the user to the login screen or show a notification
            return; // Skip setting the user as the current user since they are being signed out
          }
        } catch (error) {
          if (error.response?.status === 404 && !authInProgress) {
            logMessage(
              'error',
              `AuthProvider(). User not found in the database: ${error.message}! Logging off...`
            );
            await forceLogout();
          }
          logMessage(
            'error',
            `AuthProvider(). Error fetching user data: ${error.message}`
          );
        }
      }
      if (!authInProgress) {
        setCurrentUser(user);
      }
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <GenericLoader />
      </div>
    );
  }

  const refreshUser = async () => {
    if (currentUser && currentUser.DBuser) {
      try {
        // Fetch updated user data
        const updatedUser = await apiRequest(
          'GET',
          `/user/${currentUser.DBuser._id}`
        );
        const newUserState = {
          ...currentUser,
          DBuser: updatedUser,
        };

        // Update state and return new user data
        setCurrentUser(newUserState);
        return newUserState;
      } catch (error) {
        logMessage(
          'error',
          `AuthProvider(). Error refreshing user: ${error.message}`
        );
      }
    } else {
      console.log(
        'AuthProvider(). RefreshUser(). No DBuser found. Fetching user data...'
      );
      currentUser.DBuser = await getUserByFirebaseUid(currentUser.uid);
      saveUserDataToLocalStorage(currentUser);
    }
  };

  const refreshBalance = async () => {
    if (currentUser && currentUser.DBuser) {
      try {
        // Fetch updated balance
        const updatedBalance = await apiRequest(
          'GET',
          `/user/balance/${currentUser.DBuser._id}`
        );
        setCurrentUser({
          ...currentUser,
          DBuser: {
            ...currentUser.DBuser,
            balance: updatedBalance,
          },
        });
      } catch (error) {
        logMessage(
          'error',
          `AuthProvider(). Error refreshing balance: ${error.message}`
        );
      }
    }
  };

  const handleUserSignup = async (user, existingDBUser = null) => {
    logMessage(
      'VERBOSE',
      `handleUserSignup(). Starting signup process for user: ${user.email}`
    );
    try {
      // Get the stored name for email link sign-in
      const storedName = window.localStorage.getItem('fullNameForSignIn');
      logMessage(
        'VERBOSE',
        `handleUserSignup(). Stored name from localStorage: ${storedName || 'not found'}`
      );

      // Check for pending invite in sessionStorage
      const pendingInvite = JSON.parse(sessionStorage.getItem('pendingInvite'));
      logMessage(
        'VERBOSE',
        `handleUserSignup(). Checking for pending invite: ${pendingInvite ? JSON.stringify(pendingInvite) : 'not found'}`
      );

      const newUserData = {
        email: user.email,
        name: user.displayName || storedName || '',
        firebaseUid: user.uid,
      };
      logMessage(
        'VERBOSE',
        `handleUserSignup(). New user data prepared: ${JSON.stringify(newUserData)}`
      );

      const isEmailLinkSignIn =
        localStorage.getItem('emailAuthInProgress') === 'true';
      logMessage(
        'VERBOSE',
        `handleUserSignup(). Is email link sign-in: ${isEmailLinkSignIn}`
      );

      // Handle referral code
      user.referralCode = getLocalStorageItem('referralCode') || null;
      logMessage(
        'VERBOSE',
        `handleUserSignup(). Email link sign-in referral code: ${user.referralCode || 'none'}`
      );

      // Check for existing user first
      let existingUser = existingDBUser;
      if (!existingUser) {
        try {
          logMessage(
            'VERBOSE',
            `handleUserSignup(). No existing user provided, checking by email: ${user.email}`
          );
          existingUser = await getUserDataByEmail(user.email);
        } catch (error) {
          logMessage(
            'ERROR',
            `handleUserSignup(). Error getting user data by email: ${error.message}`
          );
        }
      }

      let newDBUser;
      if (existingUser) {
        logMessage(
          'VERBOSE',
          `handleUserSignup(). Found existing user: ${existingUser.email}`
        );
        newDBUser = { user: existingUser, isNew: false };
      } else {
        // Create new user if none exists
        logMessage(
          'VERBOSE',
          `handleUserSignup(). Creating new user: ${user.email}`
        );
        newDBUser = await checkExistingOrCreateNewUser(newUserData);
        if (!newDBUser || !newDBUser.user) {
          logMessage('ERROR', 'handleUserSignup(). Failed to create/get user');
          throw new Error('Error creating new user!');
        }
      }
      logMessage(
        'VERBOSE',
        `handleUserSignup(). NewDBUser: ${JSON.stringify(newDBUser)}`
      );

      // Handle invited user case - this should happen regardless of auth method
      if (newDBUser?.user?.status === 'invited') {
        // Activate the invited user
        logMessage(
          'VERBOSE',
          `handleUserSignup(). Updating invited user ${user.email} to active status`
        );
        const updatedUser = await updateDBUser(newDBUser.user._id, {
          status: 'active',
          firebaseUid: user.uid,
          name: user.displayName || storedName || '',
        });
        logMessage('VERBOSE', 'handleUserSignup(). User updated successfully.');

        // Update newDBUser with the updated user data
        newDBUser.user = updatedUser;
        logMessage(
          'VERBOSE',
          `handleUserSignup(). Updated newDBUser with activated user data. User ID: ${newDBUser.user._id}`
        );
      }

      // Rest of the code remains the same...
      // Update user object with DB data
      user.DBuser = newDBUser.user;
      if (!user.displayName && newDBUser.user.name) {
        user.displayName = newDBUser.user.name;
        logMessage(
          'VERBOSE',
          `handleUserSignup(). Updated display name to: ${user.displayName}`
        );
      }

      // Sync and save user details
      logMessage(
        'VERBOSE',
        `handleUserSignup(). Starting final sync for user: ${user.email}`
      );
      const syncedUser = await syncDBUserDetails(user, newDBUser.user);
      logMessage(
        'VERBOSE',
        `handleUserSignup(). Final sync completed. User ID: ${syncedUser.DBuser._id}`
      );

      setCurrentUser(syncedUser);
      saveUserDataToLocalStorage(syncedUser);

      // Clean up auth state
      resetAuthInProgress();
      if (isEmailLinkSignIn) {
        localStorage.removeItem('emailForSignIn');
        localStorage.removeItem('fullNameForSignIn');
        logMessage(
          'DEBUG',
          'handleUserSignup(). Cleaned up email link sign-in state'
        );
      }
      localStorage.removeItem('referralCode');

      logMessage(
        'VERBOSE',
        `handleUserSignup(). Successfully completed signup process for: ${user.email}`
      );
    } catch (error) {
      logMessage(
        'ERROR',
        `handleUserSignup(). Error in signup process: ${error.message}`
      );
      throw error;
    }
  };

  const handleUserLogin = async (user) => {
    logMessage(
      'VERBOSE',
      `handleUserLogin(). Starting login process for user: ${user.email}`
    );
    try {
      const userStatus = await doesUserEmailExist(user.email);
      logMessage(
        'VERBOSE',
        `handleUserLogin(). User status check result - exists: ${userStatus.exists}, status: ${userStatus.status}`
      );

      if (!userStatus.exists) {
        logMessage('DEBUG', `User not found during login: ${user.email}`);
        throw new Error('User not found');
      }

      if (userStatus.status === 'invited') {
        logMessage('DEBUG', `Invited user attempting to login: ${user.email}`);
        throw new Error('INVITED_USER');
      }

      // Get the full user data from userStatus.userData
      try {
        logMessage(
          'VERBOSE',
          `handleUserLogin(). Attempting to sync user details for: ${user.email}`
        );
        const syncedUser = await syncDBUserDetails(user, userStatus.userData);
        logMessage(
          'VERBOSE',
          `handleUserLogin(). Successfully synced user details. User ID: ${syncedUser.DBuser._id}`
        );
        setCurrentUser(syncedUser);
        saveUserDataToLocalStorage(syncedUser);
      } catch (error) {
        logMessage(
          'ERROR',
          `Error syncing user details during login: ${error.message}`
        );
        throw error;
      }
    } catch (error) {
      if (error.message === 'INVITED_USER') {
        throw new Error('INVITED_USER');
      }
      if (
        error.response?.status === 404 ||
        error.message === 'User not found'
      ) {
        throw new Error('User not found');
      }
      logMessage('ERROR', `Unexpected error during login: ${error.message}`);
      throw error;
    }
  };

  const isFeatureEnabled = (featureName) => {
    if (currentUser && currentUser.DBuser && currentUser.DBuser.features) {
      const feature = currentUser.DBuser.features.find(
        (feature) => feature.name === featureName
      );
      return feature ? feature.enabled : false;
    }
    return false;
  };

  const acceptWorkspaceInvite = async (workspaceId) => {
    logMessage(
      'DEBUG',
      `acceptWorkspaceInvite(). Accepting workspace invite for workspace: ${workspaceId}`
    );

    // Check if user is authenticated and has necessary data
    if (!currentUser || !currentUser.DBuser) {
      logMessage('ERROR', 'acceptWorkspaceInvite(). User not authenticated');
      throw new Error('User must be authenticated to accept workspace invite');
    }

    try {
      const response = await apiRequest('POST', '/workspace/accept-invite', {
        data: { workspaceId },
      });
      logMessage(
        'DEBUG',
        'acceptWorkspaceInvite(). Successfully accepted workspace invite'
      );
      await refreshUser();
      return response;
    } catch (error) {
      logMessage(
        'ERROR',
        `acceptWorkspaceInvite(). Error accepting invite: ${error.message}`
      );
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        userData,
        updateUserData,
        setCurrentUser,
        redirectResult,
        setRedirectResult,
        isFeatureEnabled,
        refreshUser,
        refreshBalance,
        advancedBalanceMode,
        setAdvancedBalanceMode,
        resetAuthInProgress,
        handleUserLogin,
        handleUserSignup,
        forceLogout,
        acceptWorkspaceInvite,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const syncDBUserDetails = async (user, existingDBUser = null) => {
  logMessage(
    'VERBOSE',
    `syncDBUserDetails(). Starting sync for user: ${user.email}`
  );
  try {
    let DBuser = existingDBUser;
    if (!DBuser) {
      logMessage(
        'VERBOSE',
        'syncDBUserDetails(). No existing DB user provided, fetching from email'
      );
      DBuser = await getUserDataByEmail(user.email);
    }

    if (!DBuser) {
      logMessage('ERROR', 'syncDBUserDetails(). User not found in backend');
      throw new Error('User not found in backend');
    }

    logMessage(
      'VERBOSE',
      `syncDBUserDetails(). Found DB user with ID: ${DBuser._id}`
    );

    // Only update Firebase display name for new users or if display name is missing
    if (!user.displayName && DBuser.name) {
      try {
        logMessage(
          'VERBOSE',
          `syncDBUserDetails(). Updating Firebase display name to: ${DBuser.name}`
        );
        await updateFirebaseUserDisplayName(user, DBuser.name);
        user.displayName = DBuser.name; // Update local user object
      } catch (error) {
        logMessage(
          'ERROR',
          `syncDBUserDetails(). Error updating Firebase display name: ${error.message}`
        );
        // Continue even if Firebase profile update fails
      }
    }

    // Only update database if needed
    if (!DBuser.firebaseUid || DBuser.status !== 'active') {
      logMessage(
        'VERBOSE',
        `syncDBUserDetails(). Updating user status/firebaseUid in DB. Current status: ${DBuser.status}`
      );
      DBuser = await updateDBUser(DBuser._id, {
        firebaseUid: user.uid,
        status: 'active',
      });
    }

    // Update the user referral status if the user has a referral code
    if (user.referralCode) {
      try {
        logMessage(
          'VERBOSE',
          `syncDBUserDetails(). Updating referral status for code: ${user.referralCode}`
        );
        await apiRequest('PUT', `/referral/${user.referralCode}`, {
          data: {
            hasJoined: true,
          },
        });
      } catch (error) {
        logMessage(
          'ERROR',
          `syncDBUserDetails(). Error updating referral status: ${error.message}`
        );
        // Continue even if referral update fails
      }
    }

    user.DBuser = DBuser;
    logMessage(
      'VERBOSE',
      `syncDBUserDetails(). Successfully completed sync for user: ${user.email}`
    );
    return user;
  } catch (error) {
    console.error('Error syncing DB user details:', error);
    throw new Error(error.response?.data?.message || error.message);
  }
};

// Update the user data in the backend
export const updateDBUser = async (userId, userData) => {
  try {
    // Make an API request to update the user in the backend
    logMessage(
      'VERBOSE',
      `Updating user ${userId}:${JSON.stringify(userData)}`
    );
    const response = await apiRequest('PUT', `/user/${userId}`, {
      data: userData,
    });
    logMessage('VERBOSE', 'User updated successfully.');
    return response.user;
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
};

// Function to update the user display name in FIrebase
export const updateFirebaseUserDisplayName = async (user, displayName) => {
  try {
    await updateProfile(user, { displayName });
    console.log('Display name updated successfully');
  } catch (error) {
    console.error('Error updating display name:', error);
    throw error;
  }
};

export const getUserDataByEmail = async (email) => {
  try {
    // Make an API request to retrieve the user data from the backend
    logMessage(
      'VERBOSE',
      `AuthCallback(). Retrieving user data for email:${email}`
    );
    const userData = await apiRequest('POST', '/user/find/email', {
      data: { email },
    });

    if (!userData) {
      return null;
    }

    // Handle invited status
    if (userData.status === 'invited') {
      logMessage(
        'VERBOSE',
        `getUserDataByEmail(). User ${email} is in invited status`
      );
      // Return complete user data
      return {
        ...userData,
        status: 'invited',
        _id: userData._id, // Ensure ID is included
      };
    }

    return userData;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    }
    throw error;
  }
};

export const getUserByFirebaseUid = async (firebaseUid) => {
  try {
    // Make an API request to retrieve the user data from the backend
    const userData = await apiRequest(
      'GET',
      `/user/firebaseUid/${firebaseUid}`
    );
    logMessage(
      'VERBOSE',
      `getUserByFirebaseUid(). User data retrieved for user ID: ${userData._id}`
    );
    if (!userData) {
      throw new Error('No user found!');
    }
    return userData;
  } catch (error) {
    logMessage(
      'ERROR',
      `getUserByFirebaseUid(). Error retrieving user data: ${error.message}`
    );
    throw error;
  }
};

export const doesUserEmailExist = async (email) => {
  try {
    // Use the new public endpoint with usePublicApiKey option
    const response = await apiRequest('POST', '/user/check-email', {
      data: { email },
      usePublicApiKey: true, // This will add the x-public-api-key header
    });

    // The endpoint returns { exists: true } for existing users
    if (response.exists) {
      return {
        exists: true,
        status: 'active', // Since this is a public endpoint, we don't get status
      };
    }

    // The endpoint returns 404 with { exists: false } for non-existent users
    return {
      exists: false,
      status: null,
    };
  } catch (error) {
    logMessage('ERROR', `Error checking user existence: ${error.message}`);
    return {
      exists: false,
      status: null,
    };
  }
};

export const checkExistingOrCreateNewUser = async (userData) => {
  logMessage(
    'VERBOSE',
    `checkExistingOrCreateNewUser(). Starting check for email: ${userData.email}`
  );
  const {
    email,
    name,
    userType,
    companyName,
    photoUrl = null,
    firebaseUid = null,
    authMethod = null,
  } = userData;
  let existingUser = null;
  let newUser = null;
  const status = firebaseUid ? 'active' : 'signedup';
  try {
    logMessage(
      'VERBOSE',
      'checkExistingOrCreateNewUser(). Checking for existing user'
    );
    existingUser = await getUserDataByEmail(email);
  } catch (error) {
    logMessage(
      'ERROR',
      `checkExistingOrCreateNewUser(). Error checking existing user: ${error.message}`
    );
  }
  if (existingUser) {
    logMessage(
      'VERBOSE',
      `checkExistingOrCreateNewUser(). User already exists. User ID: ${existingUser._id}`
    );
    return { user: existingUser, isNew: false };
  }

  try {
    logMessage('VERBOSE', 'checkExistingOrCreateNewUser(). Creating new user');
    const response = await apiRequest('POST', '/user', {
      data: {
        email,
        firebaseUid,
        status,
        name,
        userType,
        authMethod,
        companyName,
        photoUrl,
      },
    });
    newUser = response.user;
    logMessage(
      'VERBOSE',
      `checkExistingOrCreateNewUser(). Successfully created new user. User ID: ${newUser._id}`
    );
  } catch (error) {
    logMessage(
      'ERROR',
      `checkExistingOrCreateNewUser(). Error creating new user: ${error.message}`
    );
  }
  return { user: newUser, isNew: newUser !== null };
};

export const signInWithGooglePopup = async () => {
  logMessage(
    'VERBOSE',
    'signInWithGooglePopup(). Starting Google popup sign-in'
  );
  try {
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    provider.setCustomParameters({
      prompt: 'select_account',
    });
    logMessage(
      'VERBOSE',
      'signInWithGooglePopup(). Initialized Google provider with scopes'
    );

    const result = await signInWithPopup(auth, provider);
    if (!result) {
      logMessage(
        'ERROR',
        'signInWithGooglePopup(). No result from Google sign-in'
      );
      throw new Error('No user data found in Google account!');
    }

    const { user } = result;
    const { providerData } = user;
    const hasGoogleLogin = providerData.some(
      (data) => data.providerId === 'google.com'
    );
    const hasEmailLinkLogin = providerData.some(
      (data) => data.providerId === 'password'
    );

    logMessage(
      'VERBOSE',
      `signInWithGooglePopup(). Successfully signed in user: ${user.email}`
    );
    logMessage(
      'VERBOSE',
      `signInWithGooglePopup(). Provider data - Google: ${hasGoogleLogin}, Email: ${hasEmailLinkLogin}`
    );

    const credential = GoogleAuthProvider.credentialFromResult(result);
    return { user, credential, hasGoogleLogin, hasEmailLinkLogin };
  } catch (error) {
    logMessage('ERROR', `signInWithGooglePopup(). Error: ${error.message}`);
    throw error;
  }
};

export const signInWithGoogleRedirect = async () => {
  try {
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    provider.setCustomParameters({
      prompt: 'select_account',
    });
    const auth = getAuth();
    await signInWithRedirect(auth, provider);
  } catch (error) {
    console.error('Error signing in with Google:', error.message);
    throw error;
  }
};

export const getSignInResult = async () => {
  const auth = getAuth();
  const result = await getRedirectResult(auth);
  if (result && result.user) {
    const { user } = result;
    const { providerData } = user;
    const hasGoogleLogin = providerData.some(
      (data) => data.providerId === 'google.com'
    );
    const hasEmailLinkLogin = providerData.some(
      (data) => data.providerId === 'password'
    );
    const credential = GoogleAuthProvider.credentialFromResult(result);

    return { user, credential, hasGoogleLogin, hasEmailLinkLogin };
  }
  return null;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
