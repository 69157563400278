import React, { useEffect, useContext } from 'react';
import { BrowserRouter, useNavigate, useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
// FullStory
import { FullStory, init as FSinit } from '@fullstory/browser';
// routes
import { setUserId } from 'firebase/analytics';
import Router from './routes';
// theme
import ThemeProvider from './theme';
import { LoaderProvider } from './providers/LoaderProvider';
import { AuthContext } from './providers/AuthProvider';
import { GlobalErrorProvider } from './providers/GlobalErrorProvider';
import { SnackbarProvider } from './providers/SnackbarProvider';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import packageJson from '../package.json';
import { analytics } from './firebase/firebase';

// ----------------------------------------------------------------------

function ErrorHandler() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleGlobalError = (event) => {
      // Only navigate to NoConnection if the error event specifically requests it
      // and we're not already on the NoConnection page
      if (
        event.detail.type === 'NO_CONNECTION' &&
        event.detail.shouldNavigate &&
        location.pathname !== '/NoConnection'
      ) {
        // Dispatch an event to clear any existing snackbars
        window.dispatchEvent(new CustomEvent('clearGlobalError'));
        navigate('/NoConnection');
      }
    };

    const handleConnectionRestored = (event) => {
      // If we're on the NoConnection page and connection is restored,
      // navigate back to the previous location or home
      if (location.pathname === '/NoConnection') {
        const previousLocation = event.detail.previousLocation || '/home';
        // Update the page title back to default
        document.title = 'Wundamental';
        // Use replace to avoid adding to history stack
        navigate(previousLocation, { replace: true });
      }
    };

    window.addEventListener('globalError', handleGlobalError);
    window.addEventListener('connectionRestored', handleConnectionRestored);

    return () => {
      window.removeEventListener('globalError', handleGlobalError);
      window.removeEventListener(
        'connectionRestored',
        handleConnectionRestored
      );
    };
  }, [navigate, location.pathname]);

  return null;
}

export default function App() {
  const FSOrgID = process.env.REACT_APP_FULLSTORY_ORGID;
  const { currentUser } = useContext(AuthContext);

  // Initialize FullStory with your organization ID
  if (FSOrgID) {
    FSinit({ orgId: FSOrgID });
  }

  useEffect(() => {
    // Set the user ID using the setUserId method
    if (currentUser) {
      setUserId(analytics, currentUser.DBuser?._id);
    }

    if (FSOrgID) {
      if (currentUser) {
        FullStory('setIdentity', {
          uid: currentUser.DBuser?._id,
        });
      } else {
        FullStory('setIdentity', { anonymous: true });
      }
    }
  }, [FSOrgID, currentUser]);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <LoaderProvider>
            <GlobalErrorProvider>
              <SnackbarProvider>
                <ScrollToTop />
                <StyledChart />
                <ErrorHandler />
                <Helmet>
                  <meta name="version" content={packageJson.version} />
                </Helmet>
                <Router />
              </SnackbarProvider>
            </GlobalErrorProvider>
          </LoaderProvider>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
