import React, { createContext, useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import GenericSnackbar from '../components/snackbar/GenericSnackbar';

const GlobalErrorContext = createContext();

export const useGlobalError = () => {
  const context = useContext(GlobalErrorContext);
  if (!context) {
    throw new Error('useGlobalError must be used within a GlobalErrorProvider');
  }
  return context;
};

export const GlobalErrorProvider = ({ children }) => {
  const snackbarRef = useRef();

  useEffect(() => {
    const handleGlobalError = (event) => {
      if (event.detail.type === 'NO_CONNECTION') {
        const { retryCount, maxRetries } = event.detail;
        const retryMessage = retryCount
          ? ` (Retry ${retryCount} of ${maxRetries})`
          : '';

        snackbarRef.current?.showSnackbar({
          message: `Network error occurred. Trying again...${retryMessage}`,
          severity: 'error',
          autoHideDuration: 0, // Don't auto-hide during retries
        });
      }
    };

    const handleConnectionRestored = () => {
      // First clear any existing error snackbars
      snackbarRef.current?.closeSnackbar();

      // Then show the success message
      snackbarRef.current?.showSnackbar({
        message: 'Connection restored!',
        severity: 'success',
        autoHideDuration: 3000,
      });
    };

    const handleClearError = () => {
      snackbarRef.current?.closeSnackbar();
    };

    window.addEventListener('globalError', handleGlobalError);
    window.addEventListener('connectionRestored', handleConnectionRestored);
    window.addEventListener('clearGlobalError', handleClearError);

    return () => {
      window.removeEventListener('globalError', handleGlobalError);
      window.removeEventListener(
        'connectionRestored',
        handleConnectionRestored
      );
      window.removeEventListener('clearGlobalError', handleClearError);
    };
  }, []);

  const value = {
    showError: (message) => {
      snackbarRef.current?.showSnackbar({
        message,
        severity: 'error',
        autoHideDuration: 5000,
      });
    },
    clearError: () => {
      snackbarRef.current?.closeSnackbar();
    },
  };

  return (
    <GlobalErrorContext.Provider value={value}>
      {children}
      <GenericSnackbar
        ref={snackbarRef}
        zIndex={9999}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </GlobalErrorContext.Provider>
  );
};

GlobalErrorProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
