import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Stack,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  TextField,
  InputAdornment,
  Card,
  CardContent,
  Box,
  useTheme,
  Badge,
  Chip,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { apiRequest } from '../../api/api';
import WundaBoard from '../../components/wundamental/WundaBoard';
import WundaTable from '../../components/wundamental/WundaTable';
import WundaFilter from '../../components/wundamental/WundaFilter';
import WundaButton from '../../components/wundamental/WundaButton';

const BOARD_COLUMNS = [
  { id: 'Signed up', title: 'Signed up' },
  { id: 'Intro scheduled', title: 'Intro scheduled' },
  { id: 'Onboarding', title: 'Onboarding' },
  { id: '1st session scheduled', title: '1st session scheduled' },
  { id: 'In weekly cycle', title: 'In weekly cycle' },
  { id: 'Feedback and conversion', title: 'Feedback and conversion' },
  { id: 'In monthly cycle', title: 'In monthly cycle' },
  { id: 'Finished', title: 'Finished' },
  { id: 'Lost', title: 'Lost' },
];

const TABLE_COLUMNS = [
  { id: 'name', label: 'Name', minWidth: 170, sortable: true },
  { id: 'email', label: 'Email', minWidth: 200, sortable: true },
  { id: 'type', label: 'Type', minWidth: 100, sortable: true },
  { id: 'wundamental.status', label: 'Status', minWidth: 150, sortable: true },
  {
    id: 'wundamental.cycle.status',
    label: 'Cycle Status',
    minWidth: 150,
    sortable: true,
  },
  {
    id: 'wundamental.cycle.number',
    label: 'Cycle #',
    minWidth: 100,
    sortable: true,
  },
  {
    id: 'wundamental.preferredPackage',
    label: 'Preferred Package',
    minWidth: 150,
    sortable: true,
  },
  {
    id: 'wundamental.pendingActions',
    label: 'Pending Actions',
    minWidth: 200,
    sortable: false,
    render: (value) => (
      <Stack direction="row" spacing={0.5} flexWrap="wrap" gap={0.5}>
        {value?.map((action, index) => (
          <Chip
            key={index}
            label={action}
            size="small"
            color="info"
            variant="outlined"
            sx={(theme) => ({
              height: 20,
              borderColor: theme.palette.info.light,
              color: theme.palette.info.dark,
              '& .MuiChip-label': {
                px: 1,
                py: 0,
                fontSize: '0.75rem',
              },
            })}
          />
        ))}
      </Stack>
    ),
  },
];

const FILTER_FIELDS = [
  { id: 'name', label: 'Name', type: 'text' },
  { id: 'type', label: 'Type', type: 'text' },
  { id: 'email', label: 'Email', type: 'text' },
  { id: 'wundamental.cycle.status', label: 'Cycle Status', type: 'text' },
  {
    id: 'wundamental.remaining.minutes',
    label: 'Remaining Minutes',
    type: 'number',
  },
];

const ClientsPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [view, setView] = useState('board');
  const [search, setSearch] = useState('');
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [activeFilters, setActiveFilters] = useState([]);
  const [columnStates, setColumnStates] = useState({});
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const fetchClients = useCallback(
    async (directParams = {}, complexFilter = null) => {
      try {
        setLoading(true);
        setError(null);

        // For board view, fetch all clients without pagination
        const response = await apiRequest('GET', '/clients', {
          params: {
            ...(view === 'table'
              ? {
                  page: page + 1,
                  limit: rowsPerPage,
                }
              : {
                  // No pagination for board view
                  limit: 1000, // Set a high limit to get all clients
                }),
            search: search,
            sortBy,
            sortOrder,
            ...directParams,
            ...(complexFilter && {
              filter: JSON.stringify(complexFilter),
            }),
          },
        });

        setClients(response.clients || []);
        setTotalRows(response.total || 0);
      } catch (err) {
        console.error('API Error:', err);
        setError(err.message || 'Failed to fetch clients');
      } finally {
        setLoading(false);
      }
    },
    [page, rowsPerPage, search, sortBy, sortOrder, view]
  );

  const fetchColumnClients = useCallback(
    async (status, page = 1, append = false) => {
      try {
        // Update loading state for this column
        setColumnStates((prev) => ({
          ...prev,
          [status]: {
            ...prev[status],
            isLoading: true,
          },
        }));

        const response = await apiRequest('GET', '/clients', {
          params: {
            boardView: true,
            columnStatus: status,
            page,
            limit: 10,
            search,
            ...((activeFilters.length > 0 || search) && {
              filter: JSON.stringify(
                activeFilters.reduce((acc, filter) => {
                  const { field, operator, value } = filter;
                  if (operator === 'contains') {
                    acc[field] = { $regex: value, $options: 'i' };
                  } else {
                    acc[field] = value;
                  }
                  return acc;
                }, {})
              ),
            }),
          },
        });

        if (response) {
          setColumnStates((prev) => ({
            ...prev,
            [status]: {
              clients: append
                ? [
                    ...(prev[status]?.clients || []),
                    ...(response.clients || []),
                  ]
                : response.clients || [],
              currentPage: page,
              hasNextPage: response.hasNextPage,
              total: response.total,
              isLoading: false,
            },
          }));
        }
      } catch (err) {
        console.error(`Error fetching clients for column ${status}:`, err);
        setColumnStates((prev) => ({
          ...prev,
          [status]: {
            ...prev[status],
            isLoading: false,
            error: err.message,
          },
        }));
      }
    },
    [search, activeFilters]
  );

  const initializeBoard = useCallback(async () => {
    try {
      setIsInitialLoad(true);

      // Initialize column states for all columns
      const initialStates = {};
      BOARD_COLUMNS.forEach((column) => {
        initialStates[column.id] = {
          clients: [],
          currentPage: 0,
          hasNextPage: true,
          isLoading: false,
          total: 0,
        };
      });
      setColumnStates(initialStates);

      // Fetch first page for each column
      await Promise.all(
        BOARD_COLUMNS.map((column) => fetchColumnClients(column.id, 1))
      );
    } catch (err) {
      console.error('Error initializing board:', err);
    } finally {
      setIsInitialLoad(false);
    }
  }, [fetchColumnClients]);

  const handleLoadMore = async (status) => {
    const columnState = columnStates[status];
    if (columnState?.isLoading || !columnState?.hasNextPage) return;

    await fetchColumnClients(status, columnState.currentPage + 1, true);
  };

  useEffect(() => {
    if (view === 'board') {
      initializeBoard();
    } else {
      fetchClients();
    }
  }, [view, initializeBoard, fetchClients]);

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (columnId) => {
    if (sortBy === columnId) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(columnId);
      setSortOrder('asc');
    }
  };

  const handleCardClick = (client) => {
    navigate(`/home/wundamental/clients/${client._id}`);
  };

  const handleStatusChange = async (clientId, newStatus) => {
    try {
      const updatedClient = await apiRequest('PUT', `/clients/${clientId}`, {
        data: { 'wundamental.status': newStatus },
      });

      // Update the board state directly using the response data
      setColumnStates((prev) => {
        const newStates = { ...prev };

        // Find and remove from source column
        const sourceColumn = Object.keys(newStates).find((status) =>
          newStates[status].clients.some((client) => client._id === clientId)
        );

        if (sourceColumn) {
          newStates[sourceColumn] = {
            ...newStates[sourceColumn],
            clients: newStates[sourceColumn].clients.filter(
              (client) => client._id !== clientId
            ),
            total: Math.max(0, newStates[sourceColumn].total - 1),
          };
        }

        // Add to target column
        newStates[newStatus] = {
          ...newStates[newStatus],
          clients: [updatedClient, ...newStates[newStatus].clients],
          total: newStates[newStatus].total + 1,
        };

        return newStates;
      });
    } catch (err) {
      throw new Error(err.message || 'Failed to update client status');
    }
  };

  const handleFilterApply = (filters) => {
    // Split filters into direct query params and complex filters
    const directParams = {};
    const complexFilters = [];

    filters.forEach((filter) => {
      const { field, operator, value } = filter;

      // Handle direct query parameters (simple equality checks)
      if (operator === 'is' && !field.includes('.')) {
        directParams[field] = value;
        return;
      }

      // Handle complex filters (with operators or nested fields)
      let queryPart = {};
      switch (operator) {
        case 'is':
          queryPart[field] = value;
          break;
        case 'isNot':
          queryPart[field] = { $ne: value };
          break;
        case 'contains':
          queryPart[field] = { $regex: value, $options: 'i' };
          break;
        case 'doesNotContain':
          queryPart[field] = { $not: { $regex: value, $options: 'i' } };
          break;
        case 'startsWith':
          queryPart[field] = { $regex: `^${value}`, $options: 'i' };
          break;
        case 'endsWith':
          queryPart[field] = { $regex: `${value}$`, $options: 'i' };
          break;
        case 'isEmpty':
          queryPart[field] = { $exists: false };
          break;
        case 'isNotEmpty':
          queryPart[field] = { $exists: true };
          break;
        default:
          break;
      }
      complexFilters.push(queryPart);
    });

    // Combine complex filters into a single MongoDB query
    const complexFilter =
      complexFilters.length > 0
        ? complexFilters.reduce((acc, filter) => ({ ...acc, ...filter }), {})
        : null;

    // Store active filters
    setActiveFilters(filters);

    // Update fetchClients to use both direct params and complex filter
    fetchClients(directParams, complexFilter);
  };

  const handleFilterClear = () => {
    setActiveFilters([]);
    fetchClients();
  };

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <Container
        maxWidth={false}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          py: 2,
          overflow: 'hidden',
        }}
      >
        <Stack
          spacing={2}
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
            sx={{ flexShrink: 0 }}
          >
            <Typography variant="h4">Clients</Typography>
            <Stack direction="row" spacing={2} alignItems="center">
              <TextField
                size="small"
                placeholder="Search clients..."
                value={search}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon icon="mdi:magnify" />
                    </InputAdornment>
                  ),
                }}
              />
              <WundaFilter
                fields={FILTER_FIELDS}
                onApply={handleFilterApply}
                onClear={handleFilterClear}
                activeFilters={activeFilters}
                renderTrigger={(props) => (
                  <Badge
                    badgeContent={activeFilters.length}
                    color="primary"
                    sx={{
                      '& .MuiBadge-badge': {
                        right: -3,
                        top: 13,
                      },
                    }}
                  >
                    <WundaButton
                      {...props}
                      variant="outlined"
                      startIcon={<Icon icon="mdi:filter-variant" />}
                      sx={{
                        ...(activeFilters.length > 0 && {
                          bgcolor: theme.palette.primary.lighter,
                          color: theme.palette.primary.main,
                          borderColor: theme.palette.primary.main,
                          '&:hover': {
                            bgcolor: theme.palette.primary.light,
                          },
                        }),
                      }}
                    >
                      Filters
                    </WundaButton>
                  </Badge>
                )}
              />
              <ToggleButtonGroup
                value={view}
                exclusive
                onChange={handleViewChange}
                size="small"
              >
                <ToggleButton value="board">
                  <Icon icon="mdi:view-column" />
                </ToggleButton>
                <ToggleButton value="table">
                  <Icon icon="mdi:table" />
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Stack>

          {view === 'board' ? (
            <Box
              sx={{
                flex: 1,
                overflow: 'auto',
                height: '100%',
                minHeight: 0,
              }}
            >
              <WundaBoard
                columns={BOARD_COLUMNS}
                columnStates={columnStates}
                onLoadMore={handleLoadMore}
                loading={isInitialLoad}
                error={error}
                onCardClick={handleCardClick}
                onColumnChange={(item, targetColumn) =>
                  handleStatusChange(item._id, targetColumn.title)
                }
                renderCard={(item) => (
                  <Card
                    sx={{
                      bgcolor: theme.palette.background.neutral,
                      boxShadow: theme.customShadows.z8,
                      transition:
                        'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                      cursor: 'pointer',
                      '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: theme.customShadows.z16,
                      },
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="subtitle1"
                        color="primary.main"
                        gutterBottom
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mb: 2 }}
                      >
                        {item.email}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'inline-block',
                            px: 1,
                            py: 0.5,
                            borderRadius: 1,
                            bgcolor: theme.palette.background.PurpleBG,
                            color: theme.palette.primary.main,
                            typography: 'caption',
                            alignSelf: 'flex-start',
                          }}
                        >
                          {item.type}
                        </Box>
                        {(item.wundamental?.cycle?.status ||
                          item.wundamental?.cycle?.number) && (
                          <Box
                            sx={{
                              display: 'flex',
                              gap: 1,
                              alignSelf: 'flex-start',
                            }}
                          >
                            {item.wundamental?.cycle?.number && (
                              <Box
                                sx={{
                                  display: 'inline-block',
                                  px: 1,
                                  py: 0.5,
                                  borderRadius: 1,
                                  bgcolor: theme.palette.background.OrangeBG,
                                  color: theme.palette.warning.dark,
                                  typography: 'caption',
                                }}
                              >
                                Cycle #{item.wundamental.cycle.number}
                              </Box>
                            )}
                            {item.wundamental?.cycle?.status && (
                              <Box
                                sx={{
                                  display: 'inline-block',
                                  px: 1,
                                  py: 0.5,
                                  borderRadius: 1,
                                  bgcolor: theme.palette.background.GreenBG,
                                  color: theme.palette.success.dark,
                                  typography: 'caption',
                                }}
                              >
                                {item.wundamental.cycle.status}
                              </Box>
                            )}
                          </Box>
                        )}
                        {item.wundamental?.preferredPackage && (
                          <Box
                            sx={{
                              display: 'inline-block',
                              px: 1,
                              py: 0.5,
                              borderRadius: 1,
                              bgcolor: theme.palette.background.BlueBG,
                              color: theme.palette.info.dark,
                              typography: 'caption',
                              alignSelf: 'flex-start',
                            }}
                          >
                            {item.wundamental.preferredPackage}
                          </Box>
                        )}
                        {item.wundamental?.pendingActions?.length > 0 && (
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 0.5,
                              mt: 1,
                            }}
                          >
                            {item.wundamental.pendingActions.map(
                              (action, index) => (
                                <Chip
                                  key={index}
                                  label={action}
                                  size="small"
                                  color="info"
                                  variant="outlined"
                                  sx={{
                                    borderColor: theme.palette.info.light,
                                    color: theme.palette.info.dark,
                                  }}
                                />
                              )
                            )}
                          </Box>
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                )}
              />
            </Box>
          ) : (
            <Box
              sx={{ flex: 1, overflow: 'auto', height: '100%', minHeight: 0 }}
            >
              <WundaTable
                columns={TABLE_COLUMNS}
                data={clients}
                page={page}
                rowsPerPage={rowsPerPage}
                totalRows={totalRows}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                onSort={handleSort}
                sortBy={sortBy}
                sortOrder={sortOrder}
                onRowClick={handleCardClick}
                loading={loading}
                error={error}
              />
            </Box>
          )}
        </Stack>
      </Container>
    </Box>
  );
};

export default ClientsPage;
