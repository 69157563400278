import { useState, useEffect, useContext } from 'react';
import { FlagIcon } from 'react-flag-kit';
import { logEvent } from 'firebase/analytics';
import { useTheme } from '@mui/material/styles';
import {
  TextField,
  Grid,
  Alert,
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  Select,
  InputLabel,
  FormHelperText,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  Checkbox,
  FormLabel,
  RadioGroup,
  Radio,
  Modal,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { analytics } from '../../../firebase/firebase';
import { AuthContext } from '../../../providers/AuthProvider';
import { apiRequest } from '../../../api/api';
import WundaButton from '../../../components/wundamental/WundaButton';
import WundaProgress from '../../../components/wundamental/WundaProgress';
import Iconify from '../../../components/iconify';
import languages from '../../../config/languages.json';
import axios from 'axios';
import TextEditorDialog from '../../../components/common/TextEditorDialog';

const SessionUploadForm = ({ sessionFile, onCancel, onSuccess }) => {
  const { updateUserData, userData, currentUser } = useContext(AuthContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      name: sessionFile.file.name.split('.').slice(0, -1).join('.'),
      audioFile: null,
      tags: [],
      language: userData.preferences?.defaultLanguage || 'en',
      theme: userData.defaultTheme ? userData.defaultTheme._id : null,
      sessionContext: '',
    },
  });

  const selectedLanguage = watch('language');
  const [makeDefaultLanguage, setMakeDefaultLanguage] = useState(
    userData.preferences?.defaultLanguage === 'en'
  );
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [cancelUpload, setCancelUpload] = useState(null);
  const [openContextEditor, setOpenContextEditor] = useState(false);

  const sessionContext = watch('sessionContext');

  useEffect(() => {
    if (selectedLanguage === (userData.preferences?.defaultLanguage || 'en')) {
      setMakeDefaultLanguage(true);
    } else {
      setMakeDefaultLanguage(false);
    }
  }, [selectedLanguage, userData.preferences?.defaultLanguage]);

  const handleUploadSubmit = async (data) => {
    const { name, language, flourisherName, tags, theme, sessionContext } =
      data;

    // Check if the file size exceeds 1GB
    if (sessionFile.file.size > 1024 * 1024 * 1024) {
      setErrorMessage('File size exceeds 1GB. Please upload a smaller file.');
      return;
    }

    const userId = userData._id || currentUser?.DBuser?._id;
    if (!userId) {
      setErrorMessage('User ID not found. Please try again later.');
      return;
    }

    try {
      setLoading(true);

      // 1. Get pre-signed URL from our backend
      const urlResponse = await apiRequest(
        'POST',
        '/audio/file/generate-upload-url',
        {
          data: {
            fileName: sessionFile.file.name,
            fileType: sessionFile.file.type,
            userId,
          },
        }
      );

      const { url, key: s3Key, audioFileId } = urlResponse;

      if (!audioFileId) {
        throw new Error('No audio file ID received from server');
      }

      const source = axios.CancelToken.source();
      setCancelUpload(() => (reason) => {
        source.cancel(reason || 'Upload cancelled by user');
      });

      const uploadResponse = await axios.put(url, sessionFile.file, {
        headers: {
          'Content-Type': sessionFile.file.type,
        },
        onUploadProgress: (progress) => {
          const percentage = Math.round(
            (progress.loaded / progress.total) * 100
          );
          setUploadProgress(percentage);
        },
        cancelToken: source.token,
      });

      if (uploadResponse.status !== 200) {
        throw new Error('Upload failed');
      }

      // 3. Create data object for session creation
      const sessionData = {
        name,
        language,
        fileName: sessionFile.file.name,
        fileType: sessionFile.file.type,
        s3Key,
        audioFileId,
        tags: JSON.stringify(tags),
        defaultLanguage: makeDefaultLanguage,
      };

      if (theme) {
        sessionData.themeID = theme;
      }

      if (flourisherName) {
        sessionData.flourisherName = flourisherName;
      }

      if (sessionFile.metadata?.duration != null) {
        sessionData.duration = sessionFile.metadata.duration;
      }

      if (sessionContext) {
        sessionData.sessionContext = sessionContext;
      }

      // 4. Create session in backend
      const response = await apiRequest('POST', '/session/upload', {
        data: sessionData,
      });

      if (!response) {
        throw new Error('No response from server');
      }

      if (makeDefaultLanguage) {
        await updateUserData({
          DBuser: { preferences: { defaultLanguage: language } },
        });
      }

      setSuccessMessage('Session file uploaded successfully');
      logEvent(analytics, 'new_session');
      reset();

      if (onSuccess) {
        onSuccess(successMessage);
      }

      if (onCancel) {
        onCancel();
      }
    } catch (error) {
      console.error('Upload failed:', error.message);
      setErrorMessage(
        `Error uploading audio file: ${error.response?.data?.errorDetail || error.response?.data?.message || error.message}`
      );
    } finally {
      setLoading(false);
      setCancelUpload(null);
    }
  };

  const handleCancel = () => {
    if (cancelUpload && typeof cancelUpload === 'function') {
      cancelUpload('Upload cancelled by user');
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <form onSubmit={handleSubmit(handleUploadSubmit)}>
        <Box sx={{ maxHeight: '80vh', overflow: 'auto', pb: 8 }}>
          <Grid
            container
            spacing={2}
            sx={{
              backgroundColor: theme.palette.background.paper,
              padding: '20px',
              borderRadius: '10px',
            }}
          >
            <Grid item xs={12} sx={{ width: '50%' }}>
              <Controller
                control={control}
                name="name"
                rules={{ required: 'Please enter name for your session' }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Session Name *"
                    fullWidth
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    className="fs-mask"
                  />
                )}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6} sx={{ p: 0, borderRadius: 2 }}>
              <Controller
                control={control}
                name="language"
                defaultValue={userData.preferences?.defaultLanguage || 'en'}
                rules={{
                  required:
                    'Please select a language that was spoken in the session',
                }}
                render={({ field }) => (
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="language-label">Language</InputLabel>
                    <Select
                      labelId="language-label"
                      id="language"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      label="Language"
                    >
                      {languages.map((language) => (
                        <MenuItem key={language.value} value={language.value}>
                          <Box display="flex" alignItems="center">
                            <FlagIcon code={language.flag} size={24} />
                            <Typography
                              variant="body1"
                              style={{ marginLeft: 8 }}
                            >
                              {language.label}
                            </Typography>
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      What language was spoken in the session?
                    </FormHelperText>
                    {errors.language && (
                      <FormHelperText>{errors.language.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={makeDefaultLanguage}
                    onChange={(e) => setMakeDefaultLanguage(e.target.checked)}
                    name="makeDefault"
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body2">
                    Make this as my default language
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={12} sx={{ width: '50%' }}>
              <Controller
                control={control}
                name="flourisherName"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Client Name"
                    placeholder="Optional. This name would be shown in the insight reports."
                    fullWidth
                    error={!!errors.flourisherName}
                    helperText={errors.flourisherName?.message}
                    className="fs-mask"
                  />
                )}
              />
            </Grid>
            {userData.allowedThemes && userData.allowedThemes.length > 1 && (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="theme"
                  defaultValue={
                    userData.defaultTheme ? userData.defaultTheme._id : null
                  }
                  rules={{ required: 'Please select your use case' }}
                  render={({ field }) => (
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Use case:</FormLabel>
                      <RadioGroup {...field}>
                        {userData.allowedThemes.map((theme) => (
                          <FormControlLabel
                            key={theme._id}
                            value={theme._id}
                            control={<Radio />}
                            label={theme.name}
                          />
                        ))}
                      </RadioGroup>
                      {errors && errors.themeId && (
                        <FormHelperText>
                          {errors.themeId?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Accordion
                sx={{
                  backgroundColor: theme.palette.grey[200],
                  border: '1px solid',
                  borderColor: theme.palette.grey[400],
                  marginTop: theme.spacing(2),
                  marginBottom: theme.spacing(2),
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Advanced...</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      mt: 2,
                      p: 2,
                      border: 1,
                      borderColor: 'divider',
                      borderRadius: 1,
                      backgroundColor: (theme) => theme.palette.grey[50],
                    }}
                  >
                    <TextField
                      label="Tags"
                      variant="outlined"
                      fullWidth
                      placeholder="Enter a tag and press Enter or any non-alphanumeric key"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                        }
                      }}
                      onKeyUp={(event) => {
                        // Use regex to remove any non-alphanumeric characters
                        const sanitizedValue = event.target.value
                          .replace(/[^a-zA-Z0-9]/g, '')
                          .trim();

                        // This regex checks if the key pressed is non-alphanumeric or if it's the Enter key
                        const isDelimiter =
                          /[^a-zA-Z0-9]/.test(event.key) ||
                          event.key === 'Enter';

                        if (isDelimiter && sanitizedValue !== '') {
                          // Only add the tag if it doesn't already exist in the array
                          if (!tags.includes(sanitizedValue)) {
                            const newTags = [...tags, sanitizedValue];
                            setTags(newTags);
                            setValue('tags', newTags); // update the form value too
                          }
                          // Clear the text field regardless of whether it's a duplicate or not
                          event.target.value = '';
                        }
                      }}
                    />
                    <FormHelperText>
                      Enter tags related to the session. Tags help you
                      categorize your sessions. Press Enter or any
                      non-alphanumeric key to add a tag.
                    </FormHelperText>
                    <Box mt={2}>
                      {tags.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag}
                          onDelete={() => {
                            const newTags = tags.filter((t) => t !== tag);
                            setTags(newTags);
                            setValue('tags', newTags); // also update the form value
                          }}
                          style={{ marginRight: '5px', marginBottom: '5px' }}
                        />
                      ))}
                    </Box>
                    <Controller
                      name="tags"
                      control={control}
                      render={() => null}
                      defaultValue={[]}
                    />
                  </Box>
                  <Box
                    sx={{
                      mt: 2,
                      p: 2,
                      border: 1,
                      borderColor: 'divider',
                      borderRadius: 1,
                      backgroundColor: (theme) => theme.palette.grey[50],
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <WundaButton
                        variant="outlined"
                        size="small"
                        onClick={() => setOpenContextEditor(true)}
                        startIcon={
                          sessionContext ? (
                            <Iconify
                              icon="mdi:text-box-check"
                              color="success.main"
                            />
                          ) : (
                            <Iconify icon="mdi:text-box-plus-outline" />
                          )
                        }
                      >
                        {sessionContext
                          ? 'Edit Session Context'
                          : 'Add Session Context'}
                      </WundaButton>
                    </Box>
                    <FormHelperText>
                      Add any additional context about this session that might
                      be helpful for analysis (optional)
                    </FormHelperText>
                  </Box>
                  <Controller
                    name="sessionContext"
                    control={control}
                    render={() => null}
                  />
                  <TextEditorDialog
                    open={openContextEditor}
                    onClose={() => setOpenContextEditor(false)}
                    onSave={(value) => {
                      setValue('sessionContext', value);
                      setOpenContextEditor(false);
                    }}
                    title="Session Context"
                    initialValue={sessionContext || ''}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
              <Typography>
                You are uploading <strong>{sessionFile.file.name}</strong> with
                this session
              </Typography>
            </Grid>
          </Grid>
          <Box mt={2}>
            {successMessage && (
              <Alert severity="success">{successMessage}</Alert>
            )}
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          </Box>
        </Box>

        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            padding: 2,
            backgroundColor: theme.palette.background.paper,
            borderTop: `1px solid ${theme.palette.divider}`,
            zIndex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 2,
          }}
        >
          <WundaButton variant="outlined" color="primary" onClick={onCancel}>
            Cancel
          </WundaButton>
          <WundaButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
          >
            Create Insights
          </WundaButton>
        </Box>
      </form>

      <Modal
        open={loading}
        onClose={loading ? null : handleCancel}
        closeAfterTransition
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '10px', // Add this line to make the corners rounded
          }}
        >
          <WundaProgress
            value={uploadProgress}
            label="Uploading your audio..."
          />
          {uploadProgress === 100 ? (
            <>
              <Iconify icon={'svg-spinners:blocks-wave'} color="#D4FF51" />
              <Typography variant="caption" gutterBottom>
                File uploaded, but please hang in there as we are preparing your
                audio for processing...
              </Typography>
            </>
          ) : (
            <Box mt={2}>
              <WundaButton
                variant="outlined"
                color="primary"
                onClick={handleCancel}
              >
                Cancel
              </WundaButton>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

SessionUploadForm.propTypes = {
  sessionFile: PropTypes.object,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default SessionUploadForm;
