function logMessage(classification, message) {
  const debugMode = process.env.REACT_APP_DEBUG_MODE === 'true';
  const verboseMode = process.env.REACT_APP_DEBUG_MODE_VERBOSE === 'true';
  const messageType = classification.toUpperCase();

  // Handle VERBOSE classification - only log if verboseMode is true
  if (messageType === 'VERBOSE') {
    if (verboseMode) {
      console.debug(`[${classification}] ${message}`);
    }
    return;
  }

  // Handle DEBUG classification - only log if debugMode is true
  if (messageType === 'DEBUG') {
    if (debugMode) {
      console.debug(`[${messageType}] ${message}`);
    }
    return;
  }

  // Handle ERROR classification
  if (messageType === 'ERROR') {
    console.error(`[${messageType}] ${message}`);
    return;
  }

  // Handle other classifications (INFO, etc)
  console.log(`[${messageType}] ${message}`);
}

export { logMessage };
