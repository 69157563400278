import { useEffect, useContext, useCallback, useState, useRef } from 'react';
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Typography, Container, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Error as ErrorIcon } from '@mui/icons-material';
import { AuthContext } from '../../providers/AuthProvider';
import GenericLoader from '../../components/loader/GenericLoader';
import WundaButton from '../../components/wundamental/WundaButton';
import { getLocalStorageItem } from '../../utils';
import { logMessage } from '../../utils/logger';

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function AuthCallback() {
  const navigate = useNavigate();
  const {
    forceLogout,
    resetAuthInProgress,
    handleUserSignup,
    handleUserLogin,
  } = useContext(AuthContext);
  const navigateRef = useRef(navigate);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [signInHandled, setSignInHandled] = useState(false);

  useEffect(() => {
    navigateRef.current = navigate;
  }, [navigate]);

  const handleSignIn = useCallback(async () => {
    if (signInHandled) return;
    setLoading(true);
    setSignInHandled(true);
    logMessage(
      'VERBOSE',
      'AuthCallback.handleSignIn(). Starting sign-in process'
    );
    const auth = getAuth();

    if (!isSignInWithEmailLink(auth, window.location.href)) {
      logMessage(
        'DEBUG',
        'AuthCallback.handleSignIn(). Not a valid email sign-in link'
      );
      setLoading(false);
      return;
    }

    let email = getLocalStorageItem('emailForSignIn');
    if (!email) {
      logMessage(
        'DEBUG',
        'AuthCallback.handleSignIn(). No email found in localStorage, prompting user'
      );
      email = window.prompt('Please provide your email for confirmation');
    }

    try {
      logMessage(
        'VERBOSE',
        'AuthCallback.handleSignIn(). Starting email link authentication'
      );
      localStorage.setItem('emailAuthInProgress', 'true');
      const result = await signInWithEmailLink(
        auth,
        email,
        window.location.href
      );
      const { user } = result;

      if (user) {
        logMessage(
          'VERBOSE',
          `AuthCallback.handleSignIn(). Successfully authenticated user: ${user.email}`
        );

        // Check if this is a signup or login flow
        const isSignupFlow = !!getLocalStorageItem('fullNameForSignIn');

        try {
          if (isSignupFlow) {
            logMessage(
              'VERBOSE',
              'AuthCallback.handleSignIn(). Detected signup flow'
            );
            const name = getLocalStorageItem('fullNameForSignIn');
            user.displayName = name;
            await handleUserSignup(user);
            logMessage(
              'VERBOSE',
              'AuthCallback.handleSignIn(). Successfully completed signup process'
            );
          } else {
            logMessage(
              'VERBOSE',
              'AuthCallback.handleSignIn(). Detected login flow'
            );
            await handleUserLogin(user);
            logMessage(
              'VERBOSE',
              'AuthCallback.handleSignIn(). Successfully completed login process'
            );
          }

          navigateRef.current('/home');
        } catch (error) {
          logMessage(
            'ERROR',
            `AuthCallback.handleSignIn(). Error handling user authentication: ${error.message}`
          );
          console.error('Error handling user authentication:', error);
          await forceLogout();
          throw error;
        }
      }
    } catch (error) {
      logMessage(
        'ERROR',
        `AuthCallback.handleSignIn(). Authentication error: ${error.message}`
      );
      let errorMessage = 'An error occurred during authentication.';

      switch (error.code) {
        case 'auth/invalid-action-code':
          errorMessage =
            'The sign-in link is invalid. Please check if you used the correct sign-in link.';
          break;
        case 'auth/user-disabled':
          errorMessage =
            'Your account has been disabled. Please contact support.';
          break;
        case 'auth/user-not-found':
          errorMessage =
            'No account found with this email. Please check your email or sign up.';
          break;
        case 'auth/invalid-email':
          errorMessage = 'Invalid email format. Please check your email.';
          break;
        default:
          errorMessage = `An error occurred while signing in. Please try again. ${error.message}`;
      }

      setError(errorMessage);
      await forceLogout();
    } finally {
      resetAuthInProgress();
      setLoading(false);
    }
  }, [
    signInHandled,
    forceLogout,
    resetAuthInProgress,
    handleUserSignup,
    handleUserLogin,
  ]);

  useEffect(() => {
    handleSignIn();
  }, [handleSignIn]);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <GenericLoader />
      </div>
    );
  }

  if (error) {
    return (
      <>
        <Container>
          <StyledContent
            sx={{
              textAlign: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h3" paragraph>
              Oops, something went wrong!
            </Typography>

            <Typography sx={{ color: 'text.secondary' }}>{error}</Typography>

            <Box
              component={ErrorIcon}
              sx={{ fontSize: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
            />

            <WundaButton
              onClick={() => navigate('/login')}
              size="large"
              variant="contained"
            >
              Go to login page
            </WundaButton>
          </StyledContent>
        </Container>
      </>
    );
  }

  // Return null or a default state when there's no error and not loading
  return null;
}
