import React, { useState, useCallback, useRef } from 'react';
import { logEvent } from 'firebase/analytics';
import PropTypes from 'prop-types';
import {
  Container,
  Card,
  CardContent,
  Box,
  Typography,
  Divider,
  Tooltip,
  Rating,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Alert,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import EventIcon from '@mui/icons-material/Event';
import StarIcon from '@mui/icons-material/Star';
import { useTheme } from '@mui/material/styles';
import { analytics } from '../../firebase/firebase';
import DownloadInsightsFormatDialog from './DownloadInsightsFormatDialog';
import PromptDialog from '../dialog/PromptDialog';
import StatusProgressLabel from './StatusProgressLabel';
import WundaIconButton from './WundaIconButton';
import ShareInsightsReportButton from './ShareInsightsReportButton';
import WundaButton from './WundaButton';
import { apiRequest } from '../../api/api';
import { findClosestDialogue } from '../../utils/timestampUtils';
import WundaTranscriptPreview from './WundaTranscriptPreview';
import reportService from '../../services/reportService';
import WundaInlineEdit from './WundaInlineEdit';

function InsightsReportCard({
  index,
  report,
  roleDialogue = [],
  onSuccess,
  onFailure,
  onDataRefresh,
  handleShareStatusChange = () => {},
  handleReportUpdate = () => {},
  demoForAll = false,
  isSharedView = false,
  onTimeSelect = () => {},
  shareToken = null,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentReport, setCurrentReport] = useState(report);
  const [showDownloadInsightsReportModal, setShowDownloadInsightsReportModal] =
    React.useState(false);
  const [downloadInsightsReportLoading, setDownloadInsightsReportLoading] =
    React.useState(false);
  const [openInsightsDeleteDialog, setOpenInsightsDeleteDialog] =
    useState(false);
  const [openInsightsRetryDialog, setOpenInsightsRetryDialog] = useState(false);
  const [selectedDialogue, setSelectedDialogue] = useState(null);
  const [dialoguePreviewOpen, setDialoguePreviewOpen] = useState(false);
  const [insightsReportId, setInsightsReportId] = useState(null);
  const isSavingRef = useRef(false);

  const defaultRating = (currentReport.ratings &&
    currentReport.ratings.find((r) =>
      isSharedView ? r.shareToken === shareToken : r.userId
    )) || { rating: 0 };
  const [ratingValue, setRatingValue] = useState(defaultRating.rating);
  const [ratingHover, setRatingHover] = React.useState(-1);
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const [tempRating, setTempRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [ratingKey, setRatingKey] = useState(0);
  const [ratingError, setRatingError] = useState('');

  const ratingLabels = {
    1: 'Poor',
    2: 'Below Average',
    3: 'Average',
    4: 'Good',
    5: 'Excellent',
  };

  const getRatingLabelText = (ratingValue) => ratingLabels[ratingValue] || '';

  const handleSaveRating = useCallback(async (event, rating) => {
    event.preventDefault();
    setRatingHover(-1);
    setTempRating(rating);
    setRatingError('');
    setFeedbackDialogOpen(true);
  }, []);

  const handleFeedbackSubmit = async () => {
    setRatingError('');
    const data = {
      rating: tempRating,
      role: isSharedView ? 'flourisher' : 'nurturer',
    };

    if (feedback.trim()) {
      data.feedback = feedback.trim();
    }

    try {
      const url =
        isSharedView && shareToken
          ? `/insights-report/${currentReport._id}/rating?shareToken=${shareToken}`
          : `/insights-report/${currentReport._id}/rating`;

      const response = await apiRequest('POST', url, { data });

      // Update local state with new rating
      const updatedReport = {
        ...currentReport,
        ratings: currentReport.ratings
          ? currentReport.ratings
              .filter((r) =>
                isSharedView
                  ? r.shareToken !== shareToken
                  : r.userId !== response.rating.userId
              )
              .concat([response.rating])
          : [response.rating],
      };
      setCurrentReport(updatedReport);
      handleReportUpdate(updatedReport);

      // Update displayed rating value
      setRatingValue(response.rating.rating || 0);

      // Log analytics
      logEvent(analytics, 'rate_insights_report', {
        reportId: currentReport._id,
        rating: tempRating,
        hasFeedback: !!feedback.trim(),
        method: 'insights_report_card',
      });

      // Clear feedback form and close dialog
      setFeedback('');
      setFeedbackDialogOpen(false);

      // Show success message last
      onSuccess(
        'share',
        response.message || 'Thank you! Your rating has been saved!'
      );
    } catch (error) {
      // Handle specific error cases
      if (error.response?.status === 401) {
        setRatingError(
          isSharedView
            ? 'Invalid or expired share token'
            : 'Please log in to rate this report'
        );
      } else {
        setRatingError(
          error.response?.data?.errorDetail ||
            error.response?.data?.message ||
            error.message
        );
      }
    }
  };

  const handleFeedbackCancel = () => {
    setFeedbackDialogOpen(false);
    setFeedback('');
    setTempRating(0);
    setRatingValue(defaultRating.rating);
    setRatingHover(-1);
    setRatingKey((prev) => prev + 1);
    setRatingError('');
  };

  const handleDownloadInsightsReport = async (format) => {
    try {
      let response;
      let downloadUrl;
      let fileExtension;

      if (format === 'pdf') {
        response = await apiRequest(
          'GET',
          `/insights-report/download/pdf/${insightsReportId}`,
          { responseType: 'blob' }
        );
        downloadUrl = URL.createObjectURL(response);
        fileExtension = 'pdf';
      } else {
        response = await apiRequest(
          'GET',
          `/insights-report/download/${insightsReportId}`
        );
        downloadUrl = response.url;
        fileExtension = 'docx';
      }

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `report_${insightsReportId}.${fileExtension}`;
      document.body.appendChild(link);
      logEvent(analytics, 'download_insights_report', {
        reportId: insightsReportId,
        format,
        method: 'insights_report_card',
      });
      link.click();

      if (format === 'pdf') {
        URL.revokeObjectURL(downloadUrl); // Cleanup for blob URL
      }

      link.remove();
      onSuccess(
        'download',
        `Successfully downloaded the report as ${fileExtension.toUpperCase()}!`
      );
    } catch (error) {
      console.error(error);
      onFailure(
        'download',
        `Error downloading a report! ${error.response?.data?.message || error.message}`
      );
    }
  };

  const handleOpenDialoguePreviewModal = useCallback(
    (timeString) => {
      const dialogueItem = findClosestDialogue(timeString, roleDialogue);
      if (dialogueItem) {
        setSelectedDialogue(dialogueItem);
        setDialoguePreviewOpen(true);
        onTimeSelect(timeString);
      }
    },
    [roleDialogue, onTimeSelect]
  );

  const handleCloseDialoguePreviewModal = () => {
    setDialoguePreviewOpen(false);
  };

  const handleDeleteInsightsReportConfirmDialog = async () => {
    try {
      await apiRequest('DELETE', `insights-report/${insightsReportId}`);
      logEvent(analytics, 'delete_insights_report', {
        reportId: insightsReportId,
        method: 'insights_report_card',
      });
      onSuccess('delete', `Successfully deleted a report!`);
    } catch (error) {
      console.error(`Error deleting report ${insightsReportId}`, error);
      onFailure(
        'delete',
        `Error deleting a report! ${error.response?.data?.message || error.message}`
      );
    } finally {
      setOpenInsightsDeleteDialog(false);
    }
  };

  const handleInsightsRetryConfirmDialog = async () => {
    try {
      await apiRequest('POST', `insights-report/retry/${insightsReportId}`);
      logEvent(analytics, 'retry_insights_report', {
        reportId: insightsReportId,
        method: 'insights_report_card',
      });
      onSuccess('retry', 'Successfully triggered retry of the report!');
    } catch (error) {
      console.error(`Error retrying report ${insightsReportId}`, error);
      onFailure(
        'retry',
        `Error retrying a report! ${error.response?.data?.message || error.message}`
      );
    } finally {
      setOpenInsightsRetryDialog(false);
    }
  };

  const handleNurturerReportStatusChange = useCallback(
    (newStatus, reportId) => {
      setCurrentReport((r) => {
        if (r._id === reportId) {
          return { ...r, status: newStatus };
        }
        return r;
      });
      handleReportUpdate({ ...currentReport, status: newStatus });
    },
    [currentReport, handleReportUpdate]
  );

  const handleInsightsRetryConfirm = (id) => {
    setInsightsReportId(id);
    setOpenInsightsRetryDialog(true);
  };

  const handleInsightsRetryCancel = () => {
    setOpenInsightsRetryDialog(false);
  };

  const openDownloadInsightsReportDialog = (reportId) => {
    setInsightsReportId(reportId);
    setShowDownloadInsightsReportModal(true);
  };

  const handleDownloadInsightsReportCancel = () => {
    setShowDownloadInsightsReportModal(false);
  };

  const handleDownloadInsightsReportConfirm = async (format) => {
    setDownloadInsightsReportLoading(true);
    await handleDownloadInsightsReport(format);
    setDownloadInsightsReportLoading(false);
    setShowDownloadInsightsReportModal(false);
  };

  const handleShareInsightsReportConfirm = async (
    email,
    expiresInDays,
    chatEnabled = false
  ) => {
    try {
      let response;
      if (email) {
        // Email share flow
        response = await apiRequest(
          'POST',
          `/insights-report/share/${currentReport._id}/email`,
          { data: { email, expiresInDays, chatEnabled } }
        );
        onSuccess('share', 'Successfully shared the report!');
      } else {
        // Link share flow
        response = await apiRequest(
          'POST',
          `/insights-report/share/${currentReport._id}/link`,
          { data: { expiresInDays, chatEnabled } }
        );
        onSuccess('share', 'Successfully generated share link!');
      }
      // Update current report and notify parent
      const updatedReport = {
        ...currentReport,
        shareHistory: response.shareHistory,
        shareToken: response.shareToken,
      };
      setCurrentReport(updatedReport);
      handleReportUpdate(updatedReport);
      handleShareStatusChange();
    } catch (error) {
      console.error('Error sharing report:', error);
      onFailure('share', `Error sharing report! ${error.message}`);
    }
  };

  const handleDeleteInsightsReportConfirm = (reportId) => {
    setInsightsReportId(reportId);
    setOpenInsightsDeleteDialog(true);
  };

  const handleDeleteInsightsReportCancel = () => {
    setOpenInsightsDeleteDialog(false);
  };

  // Add new state for confirmation dialog
  const [confirmNewLinkOpen, setConfirmNewLinkOpen] = useState(false);
  const [pendingExpirationDays, setPendingExpirationDays] = useState(null);

  // Add handlers for the confirmation dialog
  const handleNewLinkConfirm = () => {
    if (pendingExpirationDays !== null) {
      handleShareInsightsReportConfirm(null, pendingExpirationDays).then(() => {
        setConfirmNewLinkOpen(false);
        setPendingExpirationDays(null);
      });
    }
  };

  const handleNewLinkCancel = () => {
    setConfirmNewLinkOpen(false);
    setPendingExpirationDays(null);
  };

  const handleSaveEdit = async (section, newValue) => {
    // If we're already saving, don't start another save
    if (isSavingRef.current) return;

    try {
      isSavingRef.current = true;
      const updatedReport = {
        ...currentReport,
        sections: currentReport.sections.map((s) =>
          s._id === section._id ? { ...s, content: newValue } : s
        ),
      };

      await apiRequest('PUT', `/insights-report/${currentReport._id}`, {
        data: updatedReport,
      });

      logEvent(analytics, 'edit_insights_report', {
        reportId: currentReport._id,
        method: 'insights_report_card',
      });

      setCurrentReport(updatedReport);
      handleReportUpdate(updatedReport);
      onSuccess(
        'edit',
        'Successfully updated the report content!',
        updatedReport
      );
    } catch (error) {
      console.error('Error updating report:', error);
      onFailure('edit', `Error updating report content! ${error.message}`);
    } finally {
      isSavingRef.current = false;
    }
  };

  const handleSaveTitleEdit = async (newValue) => {
    // If we're already saving, don't start another save
    if (isSavingRef.current) return;

    try {
      isSavingRef.current = true;
      const updatedReport = { ...currentReport, sessionName: newValue };

      await apiRequest('PUT', `/insights-report/${currentReport._id}`, {
        data: updatedReport,
      });

      logEvent(analytics, 'edit_insights_report_title', {
        reportId: currentReport._id,
        method: 'insights_report_card',
      });

      setCurrentReport(updatedReport);
      handleReportUpdate(updatedReport);
      onSuccess(
        'edit',
        'Successfully updated the report title!',
        updatedReport
      );
    } catch (error) {
      console.error('Error updating report title:', error);
      onFailure('edit', `Error updating report title! ${error.message}`);
    } finally {
      isSavingRef.current = false;
    }
  };

  return (
    <Container component="main" sx={{ px: 0 }}>
      <Card key={index} sx={{ mb: 2 }}>
        <CardContent>
          <Box key={index} sx={{ mb: 2 }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={12} sm={isMobile ? 8 : 6}>
                <Box sx={{ width: '100%' }}>
                  <WundaInlineEdit
                    value={currentReport.sessionName}
                    onSave={handleSaveTitleEdit}
                    disabled={isSharedView || demoForAll}
                    variant="h6"
                    color="text.primary"
                    multiline
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-root': {
                        width: '100%',
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-word',
                      },
                    }}
                    className="fs-mask"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={isMobile ? 4 : 6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  gap={0.5}
                >
                  {currentReport.status === 'COMPLETED' && (
                    <>
                      {!isSharedView && (
                        <>
                          <ShareInsightsReportButton
                            report={currentReport}
                            onFailure={onFailure}
                            onShareStatusChange={handleShareStatusChange}
                            onReportUpdate={(updatedReport) => {
                              setCurrentReport(updatedReport);
                              handleReportUpdate(updatedReport);
                            }}
                          />
                          <WundaIconButton
                            onClick={
                              demoForAll
                                ? null
                                : () =>
                                    handleDeleteInsightsReportConfirm(
                                      currentReport._id
                                    )
                            }
                            tooltip={
                              demoForAll
                                ? 'Remove this demo report (disabled)'
                                : 'Delete'
                            }
                            size="small"
                            icon="eva:trash-2-outline"
                            disabled={demoForAll}
                          />
                        </>
                      )}
                      <WundaIconButton
                        onClick={() =>
                          openDownloadInsightsReportDialog(currentReport._id)
                        }
                        tooltip="Download"
                        size="small"
                        icon="eva:download-outline"
                      />
                    </>
                  )}
                  {currentReport.status === 'FAILED' && !isSharedView && (
                    <WundaIconButton
                      onClick={() =>
                        handleInsightsRetryConfirm(currentReport._id)
                      }
                      tooltip="Retry"
                      size="small"
                      icon="eva:refresh-outline"
                    />
                  )}
                </Box>
              </Grid>
            </Grid>

            <Divider sx={{ mb: 2, mt: 1 }} />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <EventIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                  <Tooltip title="Generated at">
                    <Typography variant="body2" color="text.secondary">
                      {currentReport.createdAt
                        ? new Date(currentReport.createdAt).toLocaleDateString(
                            'en-US',
                            {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: false,
                            }
                          )
                        : ''}
                    </Typography>
                  </Tooltip>
                </Box>
                {currentReport.nurturerName && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className="fs-mask"
                  >
                    Coach: {currentReport.nurturerName}
                  </Typography>
                )}
                {currentReport.flourisherName && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className="fs-mask"
                  >
                    Coachee: {currentReport.flourisherName}
                  </Typography>
                )}
              </Box>
              {currentReport.status !== 'COMPLETED' && (
                <Box display="flex" alignItems="center" gap={1}>
                  <StatusProgressLabel
                    key={`${currentReport._id}-${currentReport.status}-${currentReport.lastError ? 'err' : 'ok'}`}
                    status={currentReport.status}
                    url={`insights-report/status/${currentReport._id}`}
                    onStatusChange={handleNurturerReportStatusChange}
                    onReady={onDataRefresh}
                    pollingInterval={15}
                    showError={
                      currentReport.status === 'FAILED' &&
                      currentReport.lastError
                        ? currentReport.lastError
                        : null
                    }
                  />
                  {(currentReport.status === 'IN_PROGRESS' ||
                    currentReport.status === 'PENDING') &&
                    !isSharedView && (
                      <WundaIconButton
                        onClick={() =>
                          handleDeleteInsightsReportConfirm(currentReport._id)
                        }
                        tooltip="Cancel Report"
                        size="small"
                        icon="eva:close-circle-outline"
                      />
                    )}
                </Box>
              )}
            </Box>
            {currentReport.status === 'COMPLETED' &&
              currentReport.sections.map((section, sectionIndex) => (
                <Box key={sectionIndex} sx={{ mt: 2 }}>
                  <Typography variant="h4" color="text.primary">
                    {section.heading}
                  </Typography>
                  <WundaInlineEdit
                    value={section.content}
                    onSave={(newValue) => handleSaveEdit(section, newValue)}
                    disabled={
                      isSharedView ||
                      demoForAll ||
                      currentReport.templateFormat === 'json'
                    }
                    multiline
                    variant="body2"
                    color="text.secondary"
                    sx={{ mt: 1 }}
                    className="fs-mask"
                  >
                    <Box className="fs-mask">
                      {currentReport.templateFormat === 'json'
                        ? reportService.formatReportContentJSON(
                            section.jsonContent,
                            handleOpenDialoguePreviewModal
                          )
                        : reportService.formatReportContent(
                            section.content,
                            currentReport,
                            handleOpenDialoguePreviewModal
                          )}
                    </Box>
                  </WundaInlineEdit>
                </Box>
              ))}
            {currentReport.status === 'COMPLETED' && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mt: 2,
                }}
              >
                {!demoForAll && (
                  <>
                    <Typography variant="body2">
                      {isSharedView
                        ? 'Rate this report'
                        : 'Rate these insights'}
                    </Typography>
                    <Rating
                      key={ratingKey}
                      name="hover-feedback"
                      value={ratingValue}
                      precision={1}
                      getLabelText={getRatingLabelText}
                      onChange={(event, newValue) => {
                        handleSaveRating(event, newValue);
                      }}
                      onChangeActive={(event, newHover) => {
                        if (!feedbackDialogOpen) {
                          setRatingHover(newHover);
                        }
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                      sx={{ color: theme.palette.primary.light }}
                    />
                    <Typography
                      variant="body2"
                      sx={{ ml: 2 }}
                      color={theme.palette.primary.main}
                    >
                      {ratingHover !== -1
                        ? ratingLabels[ratingHover]
                        : ratingLabels[ratingValue]}
                    </Typography>
                  </>
                )}
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>

      <DownloadInsightsFormatDialog
        open={showDownloadInsightsReportModal}
        isLoading={downloadInsightsReportLoading}
        onCancel={handleDownloadInsightsReportCancel}
        onConfirm={handleDownloadInsightsReportConfirm}
        onSuccess={(message) => onSuccess('download', message)}
      />

      <PromptDialog
        open={openInsightsDeleteDialog}
        title="Confirm Deletion"
        message="Are you sure you want to delete this insights report?"
        cancelButtonLabel="No"
        confirmButtonLabel="Yes"
        onCancel={handleDeleteInsightsReportCancel}
        onConfirm={handleDeleteInsightsReportConfirmDialog}
      />

      <PromptDialog
        open={openInsightsRetryDialog}
        title="Confirm Retry"
        message="Are you sure you want to retry generating this report?"
        cancelButtonLabel="Never mind"
        confirmButtonLabel="Yes"
        onCancel={handleInsightsRetryCancel}
        onConfirm={handleInsightsRetryConfirmDialog}
      />

      <WundaTranscriptPreview
        open={dialoguePreviewOpen}
        onClose={handleCloseDialoguePreviewModal}
        onGoToTranscript={() => {
          handleCloseDialoguePreviewModal();
          onTimeSelect(selectedDialogue);
        }}
        selectedDialogue={selectedDialogue}
        roleDialogue={roleDialogue}
        onTimeSelect={onTimeSelect}
      />

      <Dialog open={confirmNewLinkOpen} onClose={handleNewLinkCancel}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to create a new sharing link? This will
            invalidate any existing sharing links.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <WundaButton
            onClick={handleNewLinkCancel}
            color="primary"
            variant="outlined"
          >
            Cancel
          </WundaButton>
          <WundaButton
            onClick={handleNewLinkConfirm}
            color="primary"
            variant="contained"
          >
            Confirm
          </WundaButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={feedbackDialogOpen}
        onClose={handleFeedbackCancel}
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderRadius: 2,
            width: { xs: '95%', sm: '100%' },
            margin: { xs: 1, sm: 'auto' },
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            fontWeight: 'bold',
            padding: '20px',
          }}
        >
          Rate this Report
        </DialogTitle>
        <DialogContent sx={{ p: { xs: 2, sm: 3 }, mt: 1 }}>
          {ratingError && (
            <Box sx={{ mb: 2 }}>
              <Alert severity="error" onClose={() => setRatingError('')}>
                {ratingError}
              </Alert>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mb: 3,
            }}
          >
            <Rating
              value={tempRating}
              readOnly
              precision={1}
              size="large"
              emptyIcon={
                <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
              }
              sx={{ color: theme.palette.primary.light, mb: 1 }}
            />
            <Typography variant="body1" color="primary">
              {ratingLabels[tempRating]}
            </Typography>
          </Box>
          <DialogContentText>
            {isSharedView
              ? 'Would you like to share your thoughts about this report?'
              : 'Would you like to provide additional feedback about your experience with this report?'}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="feedback"
            label="Your Feedback (Optional)"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            sx={{ mt: 2 }}
            placeholder="Share your thoughts about this report..."
          />
        </DialogContent>
        <DialogActions sx={{ p: { xs: 2, sm: 3 }, pt: 0 }}>
          <WundaButton
            onClick={handleFeedbackCancel}
            variant="outlined"
            size="small"
          >
            Cancel
          </WundaButton>
          <WundaButton
            onClick={handleFeedbackSubmit}
            variant="contained"
            size="small"
            autoFocus
          >
            Submit Rating
          </WundaButton>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

InsightsReportCard.propTypes = {
  index: PropTypes.number,
  report: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        jsonContent: PropTypes.object,
      })
    ).isRequired,
    ratings: PropTypes.arrayOf(
      PropTypes.shape({
        rating: PropTypes.number.isRequired,
        role: PropTypes.oneOf(['nurturer', 'flourisher']).isRequired,
        feedback: PropTypes.string,
        userId: PropTypes.string,
        shareToken: PropTypes.string,
        history: PropTypes.arrayOf(
          PropTypes.shape({
            rating: PropTypes.number.isRequired,
            feedback: PropTypes.string,
            timestamp: PropTypes.string.isRequired,
          })
        ),
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
      })
    ),
    templateFormat: PropTypes.string,
    lastError: PropTypes.string,
  }).isRequired,
  roleDialogue: PropTypes.array,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  onDataRefresh: PropTypes.func.isRequired,
  handleShareStatusChange: PropTypes.func,
  handleReportUpdate: PropTypes.func,
  demoForAll: PropTypes.bool,
  isSharedView: PropTypes.bool,
  onTimeSelect: PropTypes.func,
  shareToken: PropTypes.string,
};

export default InsightsReportCard;
