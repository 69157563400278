import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';

const GenericSnackbar = forwardRef(
  ({ open, autoHideDuration, onClose, zIndex = 1400 }, ref) => {
    const [openState, setOpenState] = useState(false);
    const [messageState, setMessageState] = useState('');
    const [severityState, setSeverityState] = useState('info');
    const [actionState, setActionState] = useState(null);
    const [autoHideDurationState, setAutoHideDurationState] = useState(
      autoHideDuration || 5000
    );

    const isOpen = open !== undefined ? open.open : openState;
    const message = open ? open.message : messageState;
    const severity = open ? open.severity : severityState;
    const action = open ? open.action : actionState;

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenState(false);
      if (onClose) {
        onClose();
      }
    };

    const showSnackbar = ({
      message,
      severity,
      action = null,
      autoHideDuration,
    }) => {
      setOpenState(true);
      setMessageState(message);
      setSeverityState(severity);
      setActionState(action);
      setAutoHideDurationState(
        autoHideDuration !== 0
          ? autoHideDuration || autoHideDurationState
          : null
      );
    };

    useImperativeHandle(ref, () => ({
      showSnackbar,
      closeSnackbar: () => setOpenState(false),
      setMessage: (message) => setMessageState(message),
    }));

    useEffect(() => {
      if (isOpen && autoHideDurationState) {
        const timer = setTimeout(() => {
          setOpenState(false);
        }, autoHideDurationState);
        return () => clearTimeout(timer);
      }
      return () => {};
    }, [isOpen, autoHideDurationState]);

    return (
      <Snackbar
        open={isOpen}
        autoHideDuration={autoHideDurationState}
        onClose={handleClose}
        message={message}
        action={action}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={(theme) => ({
          zIndex,
          '& .MuiSnackbarContent-root': {
            backgroundColor:
              severity === 'error'
                ? theme.palette.error.main
                : severity === 'warning'
                  ? theme.palette.warning.main
                  : severity === 'info'
                    ? theme.palette.info.main
                    : theme.palette.secondary.main,
            color:
              severity === 'error'
                ? theme.palette.error.contrastText
                : severity === 'warning'
                  ? theme.palette.warning.contrastText
                  : severity === 'info'
                    ? theme.palette.info.contrastText
                    : theme.palette.secondary.contrastText,
          },
        })}
      />
    );
  }
);

GenericSnackbar.propTypes = {
  open: PropTypes.shape({
    open: PropTypes.bool,
    message: PropTypes.string,
    severity: PropTypes.string,
    action: PropTypes.node,
  }),
  autoHideDuration: PropTypes.number,
  onClose: PropTypes.func,
  zIndex: PropTypes.number,
};

export default GenericSnackbar;
