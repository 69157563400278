import { getAuth } from 'firebase/auth';
import axios from 'axios';
import history from '../hoc/history';
import { logMessage } from '../utils/logger';

// Handle unauthorized errors (401)
const handleUnauthorized = async () => {
  logMessage('DEBUG', 'Handling unauthorized error, clearing local storage');
  window.localStorage.removeItem('currentUser');
  const auth = getAuth();
  await auth.signOut();
  history.push('/login');
};

// Create an instance of Axios with custom configuration
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Set the base URL for your API
  headers: {
    // Set common headers or perform other configurations
    // "Authorization": "Bearer your_token",
  },
  timeout: 180000,
});

const getBaseHostURL = (fullURL) => {
  const url = new URL(fullURL);
  return `${url.protocol}//${url.host}`; // This will return "http://localhost:3000" from your full baseURL
};

// Track if we're currently retrying a request
let isRetrying = false;
let currentRetryCount = 0;
let maxRetryCount = 3;

// Add a response interceptor
api.interceptors.response.use(
  (response) =>
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    response,
  async (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response) {
      const { status } = error.response;

      if (status === 401) {
        await handleUnauthorized();
      }
    } else if (
      ['ECONNABORTED', 'Network Error', 'ERR_NETWORK'].includes(error.code) ||
      error.message === 'Network Error' ||
      error.code === 503
    ) {
      // Check if this is a silent request or if we're on NoConnection page
      const isSilentRequest =
        error.config?.headers?.['X-Silent-Request'] === 'true' ||
        window.location.pathname === '/NoConnection';

      // For network errors, only show the warning message if:
      // - we're not already retrying
      // - not on NoConnection page
      // - not a silent request
      if (!isRetrying && !isSilentRequest) {
        const event = new CustomEvent('globalError', {
          detail: {
            type: 'NO_CONNECTION',
            shouldNavigate: false,
            retryCount: currentRetryCount,
            maxRetries: maxRetryCount,
          },
        });
        window.dispatchEvent(event);
      }
    }
    // Reject the promise for all other errors
    return Promise.reject(error);
  }
);

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const apiRequest = async (
  method,
  url,
  {
    data = null,
    headers: customHeaders = {},
    params = null,
    useApiKey = false,
    usePublicApiKey = false,
    responseType = 'json',
    timeout = api.defaults.timeout,
    baseURL = null,
    onUploadProgress = null,
    setCancel = null,
    signal = null,
    maxRetries = 3,
    retryDelay = 1000,
  } = {}
) => {
  const headers = { ...api.defaults.headers.common, ...customHeaders };
  const source = axios.CancelToken.source();
  const isSilentRequest = headers['X-Silent-Request'] === 'true';

  // Connect abort signal to cancel token
  if (signal) {
    signal.addEventListener('abort', () => {
      source.cancel('Operation cancelled by abort signal');
    });
  }

  // Ensure the setCancel callback is properly set with the cancel function
  if (setCancel && typeof setCancel === 'function') {
    setCancel(source.cancel);
  }

  const doRequest = async (
    retryCount = 0,
    isAuthRetry = false,
    wasNetworkError = false
  ) => {
    try {
      const auth = getAuth();
      const { currentUser } = auth;
      if (currentUser && !useApiKey) {
        const idToken = await currentUser.getIdToken(isAuthRetry);
        headers.Authorization = idToken;
      }

      if (useApiKey) {
        const apiKey = process.env.REACT_APP_API_KEY;
        if (apiKey) {
          headers['X-API-Key'] = apiKey;
        }
      }
      if (usePublicApiKey) {
        const publicApiKey = process.env.REACT_APP_API_KEY_PUBLIC;
        headers['x-public-api-key'] = publicApiKey;
      }

      const requestBaseURL = baseURL || api.defaults.baseURL;

      if (responseType === 'stream') {
        // For streaming, use fetch with the provided signal
        const response = await fetch(`${requestBaseURL}${url}`, {
          method,
          headers: {
            ...headers,
            'Content-Type': 'application/json',
          },
          body: data ? JSON.stringify(data) : undefined,
          signal,
          keepalive: false,
        });

        if (!response.ok) {
          const error = new Error(`HTTP error! status: ${response.status}`);
          error.response = response;
          throw error;
        }

        return response;
      }

      // For non-streaming requests, use axios as before
      const requestApi = baseURL
        ? axios.create({
            baseURL: requestBaseURL,
            headers: api.defaults.headers,
          })
        : api;

      const response = await requestApi.request({
        method,
        url,
        data,
        params,
        headers,
        responseType,
        timeout,
        cancelToken: source.token,
        onUploadProgress: onUploadProgress
          ? (progressEvent) => {
              try {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                onUploadProgress(percentCompleted);
              } catch (error) {
                console.error('Error during upload:', error);
                // You can call onUploadProgress with a special value to indicate an error
                onUploadProgress(-1);
              }
            }
          : null,
      });

      // If we recovered from a network error
      if (wasNetworkError && !isSilentRequest) {
        isRetrying = false;
        const event = new CustomEvent('connectionRestored', {
          detail: {
            previousLocation: window.sessionStorage.getItem('previousLocation'),
          },
        });
        window.dispatchEvent(event);
      }

      return response?.data;
    } catch (error) {
      // Handle auth retry
      if (error.response && error.response.status === 401 && !isAuthRetry) {
        logMessage(
          'DEBUG',
          `apiRequest(). Got 401. Retrying with token refresh...`
        );
        return doRequest(retryCount, true, wasNetworkError);
      }

      if (error.response && error.response.status === 401 && isAuthRetry) {
        logMessage(
          'DEBUG',
          `apiRequest(). Got 401 even after token refresh. Logging out...`
        );
        await handleUnauthorized();
        throw error;
      }

      // Handle network errors with retry
      const isNetworkError =
        error.message === 'Network Error' ||
        ['ECONNABORTED', 'ERR_NETWORK'].includes(error.code) ||
        error.code === 503;

      if (retryCount < maxRetries && isNetworkError) {
        isRetrying = true;
        currentRetryCount = retryCount + 1;
        maxRetryCount = maxRetries;

        // Update the snackbar with new retry count, but only if not silent
        if (!isSilentRequest && window.location.pathname !== '/NoConnection') {
          const event = new CustomEvent('globalError', {
            detail: {
              type: 'NO_CONNECTION',
              shouldNavigate: false,
              retryCount: currentRetryCount,
              maxRetries: maxRetries,
            },
          });
          window.dispatchEvent(event);
        }

        const retryDelayMs = retryDelay * Math.pow(2, retryCount);
        await delay(retryDelayMs);
        return doRequest(retryCount + 1, isAuthRetry, true);
      }

      // Reset retry counters when done
      isRetrying = false;
      currentRetryCount = 0;

      // Only dispatch navigation event if it was a network error and we've exhausted retries
      // and it's not a silent request
      if (isNetworkError && !isSilentRequest) {
        window.sessionStorage.setItem(
          'previousLocation',
          window.location.pathname + window.location.search
        );

        const event = new CustomEvent('globalError', {
          detail: {
            type: 'NO_CONNECTION',
            shouldNavigate: true,
          },
        });
        window.dispatchEvent(event);
      }

      throw error;
    }
  };

  return doRequest(0, false, false);
};

export const healthcheck = async (options = {}) => {
  const { silent = false, maxRetries = 5, retryDelay = 2000 } = options;

  try {
    const baseHostURL = getBaseHostURL(api.defaults.baseURL);
    const response = await apiRequest('GET', '/healthcheck', {
      baseURL: baseHostURL,
      maxRetries,
      retryDelay,
      // Pass silent flag to prevent showing notifications
      headers: {
        'X-Silent-Request': silent ? 'true' : 'false',
      },
    });
    return response;
  } catch (error) {
    // Only navigate to NoConnection if we're not currently retrying
    // and this is not a silent request
    if (!isRetrying && !silent) {
      history.push({
        pathname: '/NoConnection',
        state: { key: Date.now() },
      });
    }
    return null;
  }
};

export default api;
