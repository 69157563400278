import React, { createContext, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import GenericSnackbar from '../components/snackbar/GenericSnackbar';

const SnackbarContext = createContext(null);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};

export const SnackbarProvider = ({ children }) => {
  const snackbarRef = useRef(null);

  const showSnackbar = (message, severity = 'success') => {
    snackbarRef.current?.showSnackbar({ message, severity });
  };

  const showSuccess = (message) => {
    showSnackbar(message, 'success');
  };

  const showError = (message) => {
    showSnackbar(message, 'error');
  };

  return (
    <SnackbarContext.Provider value={{ showSuccess, showError, showSnackbar }}>
      {children}
      <GenericSnackbar ref={snackbarRef} />
    </SnackbarContext.Provider>
  );
};

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
