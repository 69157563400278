import { useState, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { Typography, Container, Box } from '@mui/material';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import WundaButton from '../components/wundamental/WundaButton';
import { healthcheck } from '../api/api';
import { useBackgroundHealthCheck } from '../hooks/useBackgroundHealthCheck';

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function ConnectionNotAvailable() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  const handleConnectionRestored = useCallback(() => {
    const previousLocation = window.sessionStorage.getItem('previousLocation');
    // Clear the stored location to prevent stale redirects
    window.sessionStorage.removeItem('previousLocation');
    // Navigate to previous location or home if none stored
    navigate(previousLocation || '/home', { replace: true });
  }, [navigate]);

  // Use background health check with silent flag
  useBackgroundHealthCheck({
    enabled: true,
    interval: 10000,
    onConnectionRestored: handleConnectionRestored,
    silent: true, // Don't show snackbars for background checks
  });

  const handleRetry = async () => {
    setIsLoading(true);
    try {
      // Manual retry should be silent on NoConnection page to prevent showing the error snackbar
      // but should still show the success snackbar if connection is restored
      const response = await healthcheck({
        silent: true,
        maxRetries: 1, // Only try once for manual retry
      });
      if (response) {
        // First dispatch the connection restored event for the snackbar
        const event = new CustomEvent('connectionRestored', {
          detail: {
            previousLocation: window.sessionStorage.getItem('previousLocation'),
          },
        });
        window.dispatchEvent(event);

        // Then handle navigation
        handleConnectionRestored();
      }
    } catch (error) {
      // Don't need to do anything here as errors should be silent on NoConnection page
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Offline | Wundamental</title>
      </Helmet>

      <Container>
        <StyledContent
          sx={{
            textAlign: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h3" paragraph>
            Oops, we have lost the connection!
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Maybe it's on a coffee break, or perhaps it's just playing hide and
            seek. Let's wait for it to come back!
          </Typography>

          <Box
            component={WifiOffIcon}
            sx={{ fontSize: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
            color={theme.palette.secondary.main}
          />

          <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
            Checking connection automatically...
          </Typography>

          <WundaButton
            onClick={handleRetry}
            sx={{ px: 4 }}
            size="large"
            variant="contained"
            disabled={isLoading}
          >
            {isLoading ? 'Retrying...' : 'Try Now'}
          </WundaButton>
        </StyledContent>
      </Container>
    </>
  );
}
