import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Modal,
  Paper,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/Warning';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InsightsIcon from '@mui/icons-material/Insights';
import { analytics } from '../firebase/firebase';
import { AuthContext } from '../providers/AuthProvider';
import InsightsReportCard from '../components/wundamental/InsightsReportCard';
import GenericSnackbar from '../components/snackbar/GenericSnackbar';
import Iconify from '../components/iconify';
import PromptDialog from '../components/dialog/PromptDialog';
import ErrorMessage from '../components/alert/ErrorMessage';
import WundaTabPanel from '../components/wundamental/WundaTabPanel';
import GenericLoader from '../components/loader/GenericLoader';
import WundaButton from '../components/wundamental/WundaButton';
import WundaIconButton from '../components/wundamental/WundaIconButton';
import SessionEditForm from '../sections/@dashboard/session/SessionEditForm';
import Chat from '../components/chat/Chat';
import { apiRequest } from '../api/api';
import languages from '../config/languages.json';
import PropTypes from 'prop-types';
import SessionHeader from '../components/wundamental/SessionHeader';
import useChat from '../hooks/useChat';
import WundaTranscriptPreview from '../components/wundamental/WundaTranscriptPreview';
import WundaAudioPlayer from '../components/wundamental/WundaAudioPlayer';
import WundaTranscript from '../components/wundamental/WundaTranscript';
import useMediaQuery from '@mui/material/useMediaQuery';

const SessionDetailPage = ({
  isSharedView = false,
  sessionData: initialSessionData = null,
  selectedTheme: initialSelectedTheme = null,
  onThemeChange = () => {},
  tabPanelValue: externalTabPanelValue,
  onTabChange = () => {},
}) => {
  const { currentUser, setAdvancedBalanceMode, isFeatureEnabled } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const { sessionId } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [openSessionDeleteDialog, setOpenSessionDeleteDialog] = useState(false);
  const [openGenerateInsightsDialog, setOpenGenerateInsightsDialog] =
    useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [sessionData, setSessionData] = useState(initialSessionData || null);
  const [openCard, setOpenCard] = useState(() => {
    // Set initial state based on URL hash
    const hash = window.location.hash.slice(1);
    switch (hash) {
      case 'audio':
        return 'recording';
      case 'transcript':
        return 'transcript';
      case 'chat':
        return 'chat';
      default:
        return 'insights';
    }
  });
  const [showChatCard, setShowChatCard] = useState(
    () => window.location.hash === '#chat'
  );
  const [showListenCard, setShowListenCard] = useState(
    () => window.location.hash === '#audio'
  );
  const [showTranscriptCard, setShowTranscriptCard] = useState(
    () => window.location.hash === '#transcript'
  );
  const [showInsightsCard, setShowInsightsCard] = useState(
    () => window.location.hash === '#insights' || !window.location.hash
  );
  const [tabPanelValue, setTabPanelValue] = useState(
    externalTabPanelValue || 0
  );
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedTheme, setSelectedTheme] = useState(initialSelectedTheme);
  const {
    messages,
    isLoading: chatIsLoading,
    sendMessage,
    retryLastMessage,
    stopResponse,
  } = useChat(
    isSharedView ? sessionData?._id : sessionId,
    isSharedView ? sessionData?.shareToken?.token : null
  );
  const [selectedDialogue, setSelectedDialogue] = useState(null);
  const [dialoguePreviewOpen, setDialoguePreviewOpen] = useState(false);
  const transcriptRef = useRef(null);
  const snackbarRef = useRef(null);
  const audioPlayerRef = useRef(null);
  const location = useLocation();
  const [pendingSuccessMessage, setPendingSuccessMessage] = useState(null);

  const maxInsightsReports = 3;
  const language = sessionData
    ? languages.find((lang) => lang.value === sessionData?.language)
    : null;

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '100%' : '50%', // If screen size is small, width is 100%, else it's 50%.
    bgcolor: 'background.paper',
    border: '2px solid #365a47',
    boxShadow: 24,
    borderRadius: '10px',
    p: 4,
  };

  const showSuccess = useCallback((data) => {
    if (snackbarRef.current) {
      const message = typeof data === 'object' ? data.message : data;
      const severity = typeof data === 'object' ? data.severity : 'success';
      snackbarRef.current.showSnackbar({ message, severity });
    }
  }, []);

  const handleOpenChatCard = useCallback(() => {
    setOpenCard('chat');
    window.location.hash = 'chat';
    if (sessionData?._id) {
      logEvent(analytics, 'session_detail_view_chat_click', {
        sessionId: sessionData._id,
      });
    }
    setShowChatCard(true);
  }, [sessionData?._id]);

  const handleTabChange = (event, newValue) => {
    if (isSharedView) {
      onTabChange(event, newValue);
    } else {
      setTabPanelValue(newValue);
    }

    // Find the template corresponding to the new tab
    const newTemplate = selectedTheme?.templates[newValue];
    setSelectedTemplate(newTemplate);

    // Update the reports with the correct template format
    if (sessionData.insightsReports) {
      const updatedReports = sessionData.insightsReports.map((report) => {
        if (!report.templateID || report.templateID === newTemplate?._id) {
          return {
            ...report,
            templateFormat: report.templateFormat || newTemplate?.format,
          };
        }
        return report;
      });

      setSessionData((prev) => ({
        ...prev,
        insightsReports: updatedReports,
      }));
    }

    setTabPanelValue(newValue);
  };

  const handleThemeChange = (event, newTheme) => {
    if (isSharedView) {
      onThemeChange(newTheme);
    }
    setSelectedTheme(newTheme);

    // Reset template selection
    if (newTheme?.templates?.length > 0) {
      setSelectedTemplate(newTheme.templates[0]);
    }
  };

  const handleTemplateTabClick = (template) => {
    setSelectedTemplate(template);
    logEvent(analytics, 'view_template_insights_click', {
      sessionId: sessionData._id,
      templateId: template._id,
    });
  };

  const handleThemeButtonClick = (theme) => {
    if (isSharedView) {
      onThemeChange(theme);
    }

    setTabPanelValue(0);
    setSelectedTheme(theme);

    if (theme.templates?.length > 0) {
      const firstTemplate = theme.templates[0];
      setSelectedTemplate(firstTemplate);

      setSessionData((prev) => {
        if (!prev) return prev;

        const updatedReports = prev.insightsReports?.map((report) => {
          const matchingTemplate = theme.templates.find(
            (t) => t._id === report.templateID
          );
          return matchingTemplate
            ? {
                ...report,
                templateFormat:
                  report.templateFormat || matchingTemplate.format,
              }
            : report;
        });

        return {
          ...prev,
          currentTheme: theme,
          currentTemplates: theme.templates,
          insightsReports: updatedReports,
        };
      });
    }

    // Log event after state updates
    if (sessionData?._id) {
      logEvent(analytics, 'view_theme_insights_click', {
        sessionId: sessionData._id,
        themeId: theme._id,
      });
    }
  };

  useEffect(() => {
    setAdvancedBalanceMode(false);
  }, [setAdvancedBalanceMode]);

  const handleInsightReportCardActionSuccess = (
    action,
    message,
    updatedReport
  ) => {
    if (action === 'edit') {
      // Update the state directly without refreshing
      setSessionData((prevData) => ({
        ...prevData,
        insightsReports: prevData.insightsReports.map((report) =>
          report._id === updatedReport._id ? updatedReport : report
        ),
      }));
      showSuccess(message);
    } else if (action === 'download') {
      // For downloads, just show success message without refreshing
      showSuccess(message);
    } else {
      // For other actions (like delete, share, etc.), refresh the page
      handleRefreshWithMessage(message);
    }
  };

  const handleInsightReportCardActionFailure = (action, message) => {
    setErrorMessage(message);
  };

  const handleOpenListenCard = useCallback(() => {
    if (!sessionData?.audioFile) return;

    setOpenCard('recording');
    window.location.hash = 'audio';
    if (sessionData?._id) {
      logEvent(analytics, 'session_detail_view_audio_click', {
        sessionId: sessionData._id,
      });
    }
    setShowListenCard(true);
  }, [sessionData?._id, sessionData?.audioFile]);

  const handleOpenTranscriptCard = useCallback(() => {
    setOpenCard('transcript');
    window.location.hash = 'transcript';
    if (sessionData?._id) {
      logEvent(analytics, 'session_detail_view_transcript_click', {
        sessionId: sessionData._id,
      });
    }
    setShowTranscriptCard(true);
  }, [sessionData?._id]);

  const handleOpenInsightsCard = useCallback(() => {
    setOpenCard('insights');
    window.location.hash = 'insights';
    if (sessionData?._id) {
      logEvent(analytics, 'session_detail_view_insights_click', {
        sessionId: sessionData._id,
      });
    }
    setShowInsightsCard(true);
  }, [sessionData?._id]);

  const handleOpenEdit = () => {
    setShowEditModal(true);
  };

  const handleCloseEdit = async () => {
    setShowEditModal(false);
    await fetchSessionData();
  };

  const handleEditSessionSuccess = async () => {
    // Handle the success case here
    // You might want to fetch the updated data and close the modal
    handleCloseEdit();
    await fetchSessionData();
  };

  const handleDeleteSessionConfirmDialog = async () => {
    setIsLoading(true);
    try {
      await apiRequest('DELETE', `session/${sessionId}`);
      logEvent(analytics, 'delete_session', {
        sessionId,
        method: 'session_detail',
      });
      let countdown = 5;
      const intervalId = setInterval(() => {
        countdown -= 1;
        showSuccess(
          `Successfully deleted a session! Redirecting to main page in ${countdown} seconds...`
        );
        if (countdown <= 0) {
          clearInterval(intervalId);
          navigate('/home/sessions');
        }
      }, 1000);
    } catch (error) {
      console.error(`Error deleting session ${sessionId}`, error);
      setErrorMessage(`Error deleting a session! ${error.message}`);
    } finally {
      setOpenSessionDeleteDialog(false);
      setIsLoading(false);
    }
  };

  const handleGenerateInsightsReport = async () => {
    try {
      setOpenGenerateInsightsDialog(false);
      handleCloseMenu();
      setIsLoading(true);
      if (!sessionData.transcript) {
        throw new Error('Transcript not found!');
      }
      const reportData = {
        userId: currentUser.DBuser._id,
        transcriptId: sessionData.transcript._id,
        sessionId: sessionData._id,
        reportType: 'MANUAL',
        targetedAt: selectedTemplate.targetedAt,
        sessionDate: sessionData.scheduledStartAt || sessionData.createdAt,
        sessionName: sessionData.name,
        sessionDuration: sessionData.transcript.sessionDuration || null,
        nurturerName: sessionData.nurturerName || null,
        flourisherName: sessionData.flourisherName || null,
        recipientEmail: null,
        sendAsEmail: false,
        tags: sessionData.tags,
      };
      if (selectedTemplate) {
        reportData.templateId = selectedTemplate._id;
      }
      await apiRequest('POST', `/insights-report`, {
        data: reportData,
      });

      logEvent(analytics, `generate_${selectedTemplate.targetedAt}_insights`, {
        sessionId: sessionData._id,
        templateId: selectedTemplate._id,
        method: 'session_detail',
      });
      fetchSessionData();
      showSuccess('Successfully started generation of a new report!');
    } catch (error) {
      console.error(error);
      setErrorMessage(
        `Error generating a report! ${error.response?.data?.errorDetail || error.message}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerateInsightsCancel = () => {
    setOpenGenerateInsightsDialog(false);
    handleCloseMenu();
  };

  const handleGenerateInsightsConfirm = () => {
    setOpenGenerateInsightsDialog(true);
    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    // do nothing for now as we don't use menu :)
  };

  const handleDeleteSessionCancel = () => {
    setOpenSessionDeleteDialog(false);
    handleCloseMenu();
  };

  const fetchSessionData = useCallback(async () => {
    if (!sessionId && !isSharedView) {
      return;
    }

    setIsLoading(true);
    setErrorMessage(null);
    try {
      const response = await apiRequest('GET', `/session/${sessionId}`);
      setSessionData(response);
    } catch (error) {
      console.error('Failed to fetch the session data:', error);
      setErrorMessage(
        `Failed to fetch sessions. ${error.response?.data?.message || error}`
      );
    } finally {
      setIsLoading(false);
    }
  }, [sessionId, isSharedView]);

  // Effect to show pending success message after data refresh
  useEffect(() => {
    if (pendingSuccessMessage && !isLoading) {
      showSuccess(pendingSuccessMessage);
      setPendingSuccessMessage(null);
    }
  }, [pendingSuccessMessage, isLoading, showSuccess]);

  const handleRefreshWithMessage = async (message) => {
    try {
      setIsLoading(true);
      await fetchSessionData();
      if (message) {
        setPendingSuccessMessage(message);
      }
    } catch (error) {
      console.error('Error refreshing data:', error);
      const errorMessage = error.response?.data?.message || error.message;
      setErrorMessage(`Error refreshing data: ${errorMessage}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isSharedView && initialSessionData) {
      setSessionData(initialSessionData);
    } else if (sessionId) {
      fetchSessionData();
    }
  }, [isSharedView, initialSessionData, sessionId, fetchSessionData]);

  useEffect(() => {
    if (
      !selectedTheme &&
      sessionData &&
      Object.keys(sessionData).length !== 0
    ) {
      setSelectedTheme(sessionData.theme);
      if (
        sessionData.theme.templates &&
        sessionData.theme.templates.length > 0
      ) {
        setSelectedTemplate(sessionData.theme.templates[0]);
      }
    }
  }, [selectedTemplate, selectedTheme, sessionData]);

  // Update when external tab value changes
  useEffect(() => {
    if (isSharedView && externalTabPanelValue !== undefined) {
      setTabPanelValue(externalTabPanelValue);
    }
  }, [isSharedView, externalTabPanelValue]);

  // Update when external theme changes
  useEffect(() => {
    if (isSharedView && initialSelectedTheme) {
      setSelectedTheme(initialSelectedTheme);
    }
  }, [isSharedView, initialSelectedTheme]);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.slice(1);
      switch (hash) {
        case 'audio':
          handleOpenListenCard();
          break;
        case 'transcript':
          handleOpenTranscriptCard();
          break;
        case 'insights':
          handleOpenInsightsCard();
          break;
        case 'chat':
          handleOpenChatCard();
          break;
        default:
          handleOpenInsightsCard();
      }
    };

    // Handle initial hash on mount
    handleHashChange();

    // Add event listener for future changes
    window.addEventListener('hashchange', handleHashChange);

    // Cleanup
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [
    handleOpenListenCard,
    handleOpenTranscriptCard,
    handleOpenInsightsCard,
    handleOpenChatCard,
  ]);

  const generateInsightsCondition =
    sessionData &&
    ((sessionData.insightsReports.filter(
      (report) => report.targetedAt !== 'nurturer'
    ).length === 0 &&
      sessionData.transcript) ||
      (isFeatureEnabled('advanced') &&
        sessionData.transcript &&
        sessionData.insightsReports &&
        sessionData.insightsReports.filter(
          (report) => report.targetedAt !== 'nurturer'
        ).length < maxInsightsReports));

  const handleBackToSessions = () => {
    navigate('/home/sessions', {
      state: {
        ...location.state,
        fromSessionDetail: true,
      },
    });
  };

  if (isLoading) {
    return <GenericLoader />;
  }

  // Ensure sessionData is an object before proceeding
  const isSessionDataEmpty = sessionData
    ? Object.keys(sessionData).length === 0
    : true;

  if (isSessionDataEmpty && errorMessage) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <ErrorOutlineIcon color="error" style={{ fontSize: 60 }} />
        <Typography variant="h6" color="error">
          {`An error occurred. ${errorMessage || errorMessage.message}`}
        </Typography>
        {/* Ensure errorMessage.message is accessed only if errorMessage is an object */}
        <Typography>
          {errorMessage && errorMessage.message ? errorMessage.message : ''}
        </Typography>
      </Box>
    );
  }

  if (isSessionDataEmpty) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <WarningIcon color="warning" />
        <Typography variant="h6">No session data available</Typography>
      </Box>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleShareStatusChange = async () => {
    try {
      const response = await apiRequest('GET', `/session/${sessionId}`);
      setSessionData((prevData) => ({
        ...prevData,
        shareToken: response.shareToken
          ? {
              token: response.shareToken.token,
              expiresAt: response.shareToken.expiresAt,
              chatEnabled: response.shareToken.chatEnabled,
              audioEnabled: response.shareToken.audioEnabled,
              transcriptEnabled: response.shareToken.transcriptEnabled,
            }
          : null,
        shareTokenExpiresAt: response.shareToken?.expiresAt,
      }));
    } catch (error) {
      console.error('Failed to update share status:', error);
      setErrorMessage(
        `Failed to update share status. ${error.response?.data?.message || error}`
      );
    }
  };

  // Add handler for report updates
  const handleReportUpdate = (updatedReport) => {
    setSessionData((prevData) => ({
      ...prevData,
      insightsReports: prevData.insightsReports.map((report) =>
        report._id === updatedReport._id ? updatedReport : report
      ),
    }));
  };

  return (
    <Container>
      {errorMessage && (
        <ErrorMessage message={errorMessage} setMessage={setErrorMessage} />
      )}
      {isLoading ? (
        <GenericLoader />
      ) : (
        <>
          {!isSharedView && (
            <WundaButton
              onClick={handleBackToSessions}
              startIcon={<ArrowBackIcon />}
              sx={{ mb: 2 }}
              color="inherit"
            >
              Back to sessions
            </WundaButton>
          )}

          <SessionHeader
            sessionData={{
              ...sessionData,
              shareToken: sessionData?.shareToken
                ? {
                    token: sessionData.shareToken.token,
                    expiresAt: sessionData.shareToken.expiresAt,
                    chatEnabled: sessionData.shareToken.chatEnabled,
                    audioEnabled: sessionData.shareToken.audioEnabled,
                    transcriptEnabled: sessionData.shareToken.transcriptEnabled,
                  }
                : null,
            }}
            isSharedView={isSharedView}
            showShareControls={!isSharedView}
            handleOpenEdit={handleOpenEdit}
            handleDeleteSessionConfirm={() => setOpenSessionDeleteDialog(true)}
            handleShareStatusChange={handleShareStatusChange}
            handleOpenListenCard={handleOpenListenCard}
            handleOpenTranscriptCard={handleOpenTranscriptCard}
            handleOpenInsightsCard={handleOpenInsightsCard}
            handleOpenChatCard={handleOpenChatCard}
            openCard={openCard}
            language={language}
            showCards={true}
          />

          <Paper>
            {showListenCard && openCard === 'recording' && (
              <WundaAudioPlayer
                ref={audioPlayerRef}
                audioFile={sessionData.audioFile}
                isSharedView={isSharedView}
                demoForAll={sessionData.demoForAll}
                onSuccess={showSuccess}
                onError={setErrorMessage}
                onDelete={fetchSessionData}
                sessionId={sessionId}
                actions={
                  <Box display="flex" justifyContent="flex-end" width="100%">
                    {!isSharedView && (
                      <WundaIconButton
                        onClick={
                          sessionData.demoForAll
                            ? null
                            : () =>
                                audioPlayerRef.current?.handleDeleteConfirm()
                        }
                        tooltip={
                          sessionData.demoForAll
                            ? 'Delete (Disabled for demo)'
                            : 'Delete'
                        }
                        size="small"
                        icon="eva:trash-2-outline"
                        disabled={sessionData.demoForAll}
                      />
                    )}
                  </Box>
                }
              />
            )}
            {showTranscriptCard && openCard === 'transcript' && (
              <WundaTranscript
                ref={transcriptRef}
                transcript={sessionData.transcript}
                sessionName={sessionData.name}
                sessionId={sessionData._id}
                isSharedView={isSharedView}
                showTranscriptCard={showTranscriptCard}
                isActive={openCard === 'transcript'}
                onSuccess={showSuccess}
                onError={setErrorMessage}
                onDelete={fetchSessionData}
                setShowListenCard={setShowListenCard}
                setSelectedDialogue={setSelectedDialogue}
                setOpenCard={setOpenCard}
                generateInsightsCondition={generateInsightsCondition}
                onGenerateInsights={handleGenerateInsightsConfirm}
                demoForAll={sessionData.demoForAll}
                sessionData={sessionData}
                onDataRefresh={handleRefreshWithMessage}
                onTimeSelect={(dialogueItem) => {
                  if (transcriptRef.current?.handleTimeSelect) {
                    transcriptRef.current.handleTimeSelect(dialogueItem);
                  }
                }}
              />
            )}
            {showInsightsCard && openCard === 'insights' && (
              <>
                {/* Theme toggle buttons - only show if multiple themes are available */}
                {sessionData.allowedThemes.filter((theme) => {
                  if (isSharedView) {
                    return theme.templates.some((template) =>
                      sessionData.insightsReports?.some(
                        (report) =>
                          (!report.templateID ||
                            report.templateID === template._id) &&
                          report.status === 'COMPLETED'
                      )
                    );
                  }
                  return theme.templates && theme.templates.length > 0;
                }).length > 1 && (
                  <Box width={1}>
                    <ToggleButtonGroup
                      value={
                        selectedTheme
                          ? sessionData.allowedThemes.findIndex(
                              (theme) => theme._id === selectedTheme._id
                            )
                          : 0
                      }
                      exclusive
                      onChange={(event, newThemeIndex) =>
                        handleThemeChange(
                          event,
                          sessionData.allowedThemes[newThemeIndex]
                        )
                      }
                      aria-label="theme selection"
                      fullWidth
                    >
                      {sessionData.allowedThemes
                        .filter((theme) => {
                          if (isSharedView) {
                            return theme.templates.some((template) =>
                              sessionData.insightsReports?.some(
                                (report) =>
                                  (!report.templateID ||
                                    report.templateID === template._id) &&
                                  report.status === 'COMPLETED'
                              )
                            );
                          }
                          return theme.templates && theme.templates.length > 0;
                        })
                        .map((themeObject, index) => (
                          <ToggleButton
                            key={index}
                            onClick={() => handleThemeButtonClick(themeObject)}
                            value={index}
                            aria-label={themeObject.name}
                            style={{
                              color:
                                index ===
                                (selectedTheme
                                  ? sessionData.allowedThemes.findIndex(
                                      (theme) => theme._id === selectedTheme._id
                                    )
                                  : 0)
                                  ? theme.palette.secondary.contrastText
                                  : 'inherit',
                              backgroundColor:
                                index ===
                                (selectedTheme
                                  ? sessionData.allowedThemes.findIndex(
                                      (theme) => theme._id === selectedTheme._id
                                    )
                                  : 0)
                                  ? theme.palette.secondary.main
                                  : 'transparent',
                            }}
                          >
                            {themeObject.name}
                          </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                  </Box>
                )}

                {/* Template tabs */}
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    mb: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    '& .MuiTabs-root': {
                      maxWidth: '800px',
                    },
                  }}
                >
                  <Tabs
                    value={tabPanelValue}
                    onChange={handleTabChange}
                    aria-label="insights tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    sx={
                      {
                        // ... existing sx props ...
                      }
                    }
                  >
                    {sessionData.allowedThemes &&
                      selectedTheme &&
                      sessionData.allowedThemes
                        .filter((theme) => {
                          // Only process the currently selected theme
                          if (theme._id !== selectedTheme._id) return false;

                          // Check if theme has valid templates
                          if (!theme.templates || theme.templates.length === 0)
                            return false;

                          // For shared view, ensure at least one template has a completed report
                          if (isSharedView) {
                            return theme.templates.some((template) =>
                              sessionData.insightsReports?.some(
                                (report) =>
                                  (!report.templateID ||
                                    report.templateID === template._id) &&
                                  report.status === 'COMPLETED'
                              )
                            );
                          }

                          return true;
                        })
                        .flatMap((theme, themeIndex) =>
                          theme.templates
                            .filter((template) => {
                              if (isSharedView) {
                                return sessionData.insightsReports?.some(
                                  (report) =>
                                    (!report.templateID ||
                                      report.templateID === template._id) &&
                                    report.status === 'COMPLETED'
                                );
                              }
                              return true;
                            })
                            .map((template, templateIndex) => (
                              <Tab
                                key={`${themeIndex}-${templateIndex}`}
                                icon={
                                  <Iconify
                                    icon={template.icon}
                                    width={24}
                                    height={24}
                                  />
                                }
                                label={template.labelShort}
                                onClick={() => handleTemplateTabClick(template)}
                                {...a11yProps(themeIndex)}
                              />
                            ))
                        )}
                  </Tabs>
                </Box>

                {sessionData.allowedThemes &&
                  selectedTheme &&
                  sessionData.allowedThemes
                    .filter((theme) => theme._id === selectedTheme._id)
                    .flatMap((theme, themeIndex) =>
                      theme.templates
                        .filter((template) => {
                          if (isSharedView) {
                            return sessionData.insightsReports?.some(
                              (report) =>
                                (!report.templateID ||
                                  report.templateID === template._id) &&
                                report.status === 'COMPLETED'
                            );
                          }
                          return true;
                        })
                        .map((template, templateIndex) => {
                          const tabIndex =
                            themeIndex * theme.templates.length + templateIndex;
                          return (
                            <WundaTabPanel
                              key={tabIndex}
                              value={tabPanelValue}
                              index={tabIndex}
                            >
                              <Card sx={{ mb: 2 }}>
                                <CardHeader
                                  avatar={
                                    <InsightsIcon
                                      sx={{
                                        fontSize: 40,
                                        color: (theme) =>
                                          sessionData.transcript
                                            ? 'inherit'
                                            : theme.palette.grey[500],
                                      }}
                                    />
                                  }
                                  action={
                                    <>
                                      {isFeatureEnabled('advanced') &&
                                      !isSharedView &&
                                      sessionData.transcript &&
                                      sessionData.insightsReports &&
                                      sessionData.insightsReports.length <
                                        maxInsightsReports ? (
                                        isMobile ? (
                                          <WundaIconButton
                                            icon="streamline:ai-technology-spark"
                                            onClick={
                                              handleGenerateInsightsConfirm
                                            }
                                            tooltip="Generate new variation of insights"
                                          />
                                        ) : (
                                          <WundaButton
                                            startIcon={
                                              <Iconify
                                                icon={
                                                  'streamline:ai-technology-spark'
                                                }
                                              />
                                            }
                                            onClick={
                                              handleGenerateInsightsConfirm
                                            }
                                            variant="outlined"
                                            tooltip="Generate new variation of insights"
                                          >
                                            New Insights
                                          </WundaButton>
                                        )
                                      ) : null}
                                    </>
                                  }
                                  title={template.label}
                                />
                                <CardContent sx={{ py: 1, px: 0 }}>
                                  {sessionData.insightsReports &&
                                  sessionData.insightsReports.filter(
                                    (report) =>
                                      !report.templateID ||
                                      report.templateID === template._id
                                  ).length > 0 ? (
                                    sessionData.insightsReports
                                      .filter(
                                        (report) =>
                                          !report.templateID ||
                                          report.templateID === template._id
                                      )
                                      .map((report, index) => {
                                        const matchingTemplate =
                                          selectedTheme.templates.find(
                                            (template) =>
                                              template._id === report.templateID
                                          );
                                        const reportWithFormat = {
                                          ...report,
                                          templateFormat:
                                            report.templateFormat ||
                                            matchingTemplate?.format,
                                        };
                                        return (
                                          <InsightsReportCard
                                            key={template._id + index}
                                            index={index}
                                            report={reportWithFormat}
                                            roleDialogue={
                                              sessionData.transcript
                                                ? sessionData.transcript
                                                    .roleDialogue
                                                : []
                                            }
                                            isSharedView={isSharedView}
                                            onSuccess={
                                              handleInsightReportCardActionSuccess
                                            }
                                            onFailure={
                                              handleInsightReportCardActionFailure
                                            }
                                            onDataRefresh={fetchSessionData}
                                            handleShareStatusChange={
                                              handleShareStatusChange
                                            }
                                            handleReportUpdate={
                                              handleReportUpdate
                                            }
                                            demoForAll={sessionData.demoForAll}
                                            shareToken={
                                              sessionData?.shareToken?.token
                                            }
                                          />
                                        );
                                      })
                                  ) : (
                                    <Box
                                      display="flex"
                                      flexDirection="column"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <Iconify
                                        icon="mdi:rocket-launch"
                                        width={50}
                                        height={50}
                                      />
                                      <Box maxWidth="80%">
                                        <Typography
                                          variant="h5"
                                          color="text.secondary"
                                          align="center"
                                          gutterBottom
                                        >
                                          {template.ctaTitle}
                                        </Typography>
                                        <Typography
                                          variant="body1"
                                          color="text.secondary"
                                          align="center"
                                        >
                                          {template.ctaBody}
                                        </Typography>
                                      </Box>
                                      <WundaButton
                                        sx={{ mt: 2 }}
                                        onClick={
                                          sessionData.demoForAll
                                            ? null
                                            : handleGenerateInsightsConfirm
                                        }
                                        startIcon={
                                          <Iconify
                                            icon="streamline:ai-science-spark"
                                            sx={{ fontSize: '1rem', mr: 0.5 }}
                                          />
                                        }
                                        disabled={
                                          sessionData.demoForAll ||
                                          !sessionData.transcript
                                        }
                                        tooltip={
                                          sessionData.demoForAll
                                            ? 'Disabled for demo session'
                                            : !sessionData.transcript
                                              ? 'Transcript not available'
                                              : ''
                                        }
                                      >
                                        Generate
                                      </WundaButton>
                                    </Box>
                                  )}
                                </CardContent>
                              </Card>
                            </WundaTabPanel>
                          );
                        })
                    )}
              </>
            )}
            {showChatCard &&
              openCard === 'chat' &&
              (!isSharedView ||
                (isSharedView && sessionData?.shareToken?.chatEnabled)) && (
                <Card sx={{ mb: 3 }}>
                  <CardContent sx={{ height: '600px' }}>
                    <Chat
                      sessionId={sessionId}
                      messages={messages}
                      isLoading={chatIsLoading}
                      sendMessage={sendMessage}
                      retryLastMessage={retryLastMessage}
                      stopResponse={stopResponse}
                      roleDialogue={sessionData?.transcript?.roleDialogue || []}
                      onTimeSelect={(dialogueItem) => {
                        if (transcriptRef.current?.handleTimeSelect) {
                          transcriptRef.current.handleTimeSelect(dialogueItem);
                        }
                      }}
                    />
                  </CardContent>
                </Card>
              )}
          </Paper>
        </>
      )}
      <Modal open={showEditModal} onClose={handleCloseEdit}>
        <Box sx={modalStyle}>
          <SessionEditForm
            sessionId={sessionId} // Pass the session data to the form
            onCancel={handleCloseEdit}
            onSuccess={handleEditSessionSuccess}
          />
        </Box>
      </Modal>

      <PromptDialog
        open={openGenerateInsightsDialog}
        title="Confirm Generation"
        message="Are you sure you want to generate a new insights report?"
        onCancel={handleGenerateInsightsCancel}
        onConfirm={handleGenerateInsightsReport}
      />

      <PromptDialog
        open={openSessionDeleteDialog}
        title="Confirm Deletion"
        message={
          sessionData.demoForAll
            ? 'Are you sure you want to remove this demo session from your account?'
            : 'Are you sure you want to delete this session and all associated data stored?'
        }
        cancelButtonLabel="No"
        confirmButtonLabel="Yes"
        onCancel={handleDeleteSessionCancel}
        onConfirm={handleDeleteSessionConfirmDialog}
      />

      <WundaTranscriptPreview
        open={dialoguePreviewOpen}
        onClose={() => setDialoguePreviewOpen(false)}
        onGoToTranscript={() => {
          setDialoguePreviewOpen(false);
          setShowTranscriptCard(true);
          setOpenCard('transcript');
        }}
        selectedDialogue={selectedDialogue}
        roleDialogue={sessionData.transcript?.roleDialogue}
        onTimeSelect={(dialogueItem) => {
          if (transcriptRef.current?.handleTimeSelect) {
            transcriptRef.current.handleTimeSelect(dialogueItem);
          }
        }}
        handleOpenTranscriptCard={handleOpenTranscriptCard}
      />

      <GenericSnackbar ref={snackbarRef} />
    </Container>
  );
};

SessionDetailPage.propTypes = {
  isSharedView: PropTypes.bool,
  sessionData: PropTypes.object,
  selectedTheme: PropTypes.object,
  onThemeChange: PropTypes.func,
  tabPanelValue: PropTypes.number,
  onTabChange: PropTypes.func,
};

export default SessionDetailPage;
