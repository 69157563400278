import { useEffect, useRef } from 'react';
import { healthcheck } from '../api/api';

export const useBackgroundHealthCheck = (options = {}) => {
  const {
    enabled = true,
    interval = 5000, // Check every 5 seconds by default
    onConnectionRestored = null,
    silent = false, // Add silent option to suppress notifications
  } = options;

  const intervalRef = useRef(null);
  const hasRestoredRef = useRef(false);
  const isSubscribed = useRef(true);

  useEffect(() => {
    const checkHealth = async () => {
      try {
        const response = await healthcheck({
          silent, // Pass silent flag to healthcheck
          maxRetries: 1, // Only try once per check for background checks
          retryDelay: 1000,
        });

        if (response && isSubscribed.current) {
          // Clear the interval first to prevent any race conditions
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
          }

          // Only trigger the callback if we haven't already restored
          if (!hasRestoredRef.current) {
            hasRestoredRef.current = true;

            // First call the callback if provided (this handles navigation)
            if (onConnectionRestored) {
              onConnectionRestored();
            }

            // Then dispatch the event for the snackbar
            const event = new CustomEvent('connectionRestored', {
              detail: {
                previousLocation:
                  window.sessionStorage.getItem('previousLocation'),
              },
            });
            window.dispatchEvent(event);
          }
        }
      } catch (error) {
        // Reset restored flag on error to allow future restoration attempts
        hasRestoredRef.current = false;
      }
    };

    if (enabled) {
      // Reset restored flag when enabled changes
      hasRestoredRef.current = false;
      isSubscribed.current = true;

      // Do an immediate check
      checkHealth();

      // Set up the interval
      intervalRef.current = setInterval(checkHealth, interval);
    }

    return () => {
      isSubscribed.current = false;
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [enabled, interval, onConnectionRestored, silent]);

  return null;
};
