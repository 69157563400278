import { useState, useContext, useMemo } from 'react';
import { logEvent } from 'firebase/analytics';
// @mui
import { alpha } from '@mui/material/styles';
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
  Popover,
  Link,
  Tooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { analytics } from '../../../firebase/firebase';
import { AuthContext } from '../../../providers/AuthProvider';
import Iconify from '../../../components/iconify';
import { LoaderContext } from '../../../providers/LoaderProvider';
import { apiRequest } from '../../../api/api';
import GenericSnackbar from '../../../components/snackbar/GenericSnackbar';

// ----------------------------------------------------------------------

// const MENU_OPTIONS = [];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { currentUser } = useContext(AuthContext);
  const { setLoading } = useContext(LoaderContext);
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const canManageWorkspace =
    currentUser.DBuser?.workspacePermissions?.canManageWorkspace || false;

  const MENU_OPTIONS = useMemo(
    () => [
      {
        label: 'Profile',
        icon: 'eva:person-fill',
        path: '/home/profile',
      },
      ...(currentUser.DBuser?.subscription?.isActive &&
      currentUser?.DBuser?.canBuyCredits
        ? [
            {
              label: 'Manage billing',
              icon: 'eva:credit-card-fill',
              path: '/manage-billing',
            },
          ]
        : currentUser?.DBuser?.canBuyCredits
          ? [
              {
                label: 'Subscribe',
                icon: 'eva:credit-card-fill',
                path: '/home/subscribe',
              },
            ]
          : []),
    ],
    [currentUser]
  );

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMenuOptionClick = async (path) => {
    handleClose();
    if (path === '/logout') {
      // Perform logout actions here
      logEvent(analytics, 'logout', { method: 'Menu' });
    }
    if (path === '/home/profile') {
      // Perform profile actions here
      logEvent(analytics, 'profile_open', { method: 'Menu' });
    }
    if (path === '/manage-billing') {
      setLoading(true);
      try {
        const payload = {
          userId: currentUser.DBuser._id,
          isTestMode: true,
        };

        const response = await apiRequest(
          'POST',
          '/payment/create-portal-session',
          { data: payload }
        );
        logEvent(analytics, 'manage_billing_open', { method: 'Menu' });
        // Redirect the user to the URL returned by the API
        window.location = response.url;
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
      return;
    }
    navigate(path);
  };

  const handleWorkspaceClick = () => {
    if (
      !currentUser.DBuser?.subscription?.isActive ||
      !currentUser.DBuser?.subscription?.isTeamSubscription
    ) {
      setSnackbar({
        open: true,
        message: 'Workspace management requires an active team subscription',
        severity: 'warning',
      });
      handleClose();
      navigate('/home/subscribe');
      return;
    }

    if (canManageWorkspace) {
      handleClose();
      navigate('/home/workspace');
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: '', severity: 'info' });
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={currentUser.photoURL} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 250,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {currentUser.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {currentUser.email}
          </Typography>
        </Box>

        {currentUser.DBuser?.workspace &&
          currentUser.DBuser?.workspaceStatus === 'joined' &&
          currentUser.DBuser?.subscription?.isTeamSubscription &&
          currentUser.DBuser?.subscription?.isActive && (
            <>
              <Divider sx={{ borderStyle: 'dashed' }} />

              <Tooltip title="Manage your workspace" placement="left">
                <MenuItem
                  onClick={handleWorkspaceClick}
                  disabled={!canManageWorkspace}
                  sx={{
                    m: 1,
                    display: 'flex',
                    alignItems: 'center',
                    cursor: canManageWorkspace ? 'pointer' : 'default',
                  }}
                >
                  <Iconify
                    icon="ri:team-fill"
                    color="black"
                    width={20}
                    height={20}
                    style={{ marginRight: '10px' }}
                  />
                  <Typography variant="body2">
                    {currentUser.DBuser?.workspaceRole
                      ? `${
                          currentUser.DBuser?.workspaceRole
                            .charAt(0)
                            .toUpperCase() +
                          currentUser.DBuser?.workspaceRole.slice(1)
                        } of ${currentUser.DBuser?.workspaceName}`
                      : 'No role assigned'}
                  </Typography>
                </MenuItem>
              </Tooltip>
            </>
          )}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              onClick={() => handleMenuOptionClick(option.path)}
            >
              <Iconify icon={option.icon} style={{ marginRight: '10px' }} />
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => handleMenuOptionClick('/logout')}
          sx={{ m: 1 }}
        >
          <Iconify icon="ic:baseline-logout" style={{ marginRight: '10px' }} />
          Logout
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack direction="column" spacing={0.5} sx={{ p: 1 }}>
          <Box display="flex" alignItems="center">
            <Iconify
              icon="ant-design:question-circle-outlined"
              style={{ marginRight: '10px' }}
            />
            <Typography variant="body2">Help & Support</Typography>
          </Box>
          <Link href="mailto:support@wundamental.ai" color="textSecondary">
            <Typography variant="body2">support@wundamental.ai</Typography>
          </Link>
        </Stack>
      </Popover>

      <GenericSnackbar
        open={snackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        zIndex={1400}
      />
    </>
  );
}
