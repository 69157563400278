import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Container,
  Stack,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Skeleton,
  Alert,
  Box,
  Collapse,
  IconButton,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { apiRequest } from '../../api/api';

const DetailSection = ({ title, children, defaultExpanded = true }) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
    <Card>
      <CardContent>
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton onClick={() => setExpanded(!expanded)}>
            <Icon icon={expanded ? 'mdi:chevron-down' : 'mdi:chevron-right'} />
          </IconButton>
          <Typography variant="h6">{title}</Typography>
        </Stack>
        <Collapse in={expanded}>
          <Box sx={{ mt: 2 }}>{children}</Box>
        </Collapse>
      </CardContent>
    </Card>
  );
};

DetailSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  defaultExpanded: PropTypes.bool,
};

const DetailField = ({ label, value }) => (
  <Grid item xs={12} sm={6}>
    <Typography variant="subtitle2" color="text.secondary">
      {label}
    </Typography>
    <Typography variant="body1">{value || '-'}</Typography>
  </Grid>
);

DetailField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const ClientDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClient = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await apiRequest('GET', `/clients/${id}`);
        setClient(response);
      } catch (err) {
        setError(err.message || 'Failed to fetch client details');
      } finally {
        setLoading(false);
      }
    };

    fetchClient();
  }, [id]);

  if (loading) {
    return (
      <Container maxWidth="xl">
        <Stack spacing={3}>
          <Skeleton variant="rectangular" height={60} />
          <Skeleton variant="rectangular" height={200} />
          <Skeleton variant="rectangular" height={200} />
        </Stack>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="xl">
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  if (!client) {
    return (
      <Container maxWidth="xl">
        <Alert severity="warning">Client not found</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <Stack spacing={3}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Typography variant="h4">
            {client.name || 'Unnamed Client'}
          </Typography>
          <Button
            variant="outlined"
            startIcon={<Icon icon="mdi:arrow-left" />}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </Stack>

        <DetailSection title="Basic Information">
          <Grid container spacing={2}>
            <DetailField label="Name" value={client.name} />
            <DetailField label="Email" value={client.email} />
            <DetailField
              label="Alternate Email"
              value={client.alternateEmail}
            />
            <DetailField label="Phone" value={client.phone} />
            <DetailField label="Type" value={client.type} />
            <DetailField label="Goal" value={client.goal} />
          </Grid>
        </DetailSection>

        <DetailSection title="Billing Information">
          <Grid container spacing={2}>
            <DetailField label="Company" value={client.billing?.company} />
            <DetailField label="Billing Type" value={client.billing?.type} />
            <DetailField label="VAT Number" value={client.billing?.vatNumber} />
            <DetailField label="Address" value={client.billing?.address} />
          </Grid>
        </DetailSection>

        <DetailSection title="Subscription">
          <Grid container spacing={2}>
            <DetailField label="Plan" value={client.subscription?.name} />
            <DetailField
              label="Start Date"
              value={
                client.subscription?.startDate
                  ? new Date(client.subscription.startDate).toLocaleDateString()
                  : null
              }
            />
            <DetailField
              label="End Date"
              value={
                client.subscription?.endDate
                  ? new Date(client.subscription.endDate).toLocaleDateString()
                  : null
              }
            />
          </Grid>
        </DetailSection>

        <DetailSection title="Appointments">
          <Grid container spacing={2}>
            <DetailField
              label="Last Appointment"
              value={
                client.appointments?.lastDate
                  ? new Date(client.appointments.lastDate).toLocaleDateString()
                  : null
              }
            />
            <DetailField
              label="Next Appointment"
              value={
                client.appointments?.nextDate
                  ? new Date(client.appointments.nextDate).toLocaleDateString()
                  : null
              }
            />
          </Grid>
        </DetailSection>

        <DetailSection title="Wundamental Status">
          <Grid container spacing={2}>
            <DetailField label="Status" value={client.wundamental?.status} />
            <DetailField
              label="Cycle Status"
              value={client.wundamental?.cycle?.status}
            />
            <DetailField
              label="Current Cycle"
              value={client.wundamental?.cycle?.number}
            />
            <DetailField
              label="Cycle Start Date"
              value={
                client.wundamental?.cycle?.currentStartDate
                  ? new Date(
                      client.wundamental.cycle.currentStartDate
                    ).toLocaleDateString()
                  : null
              }
            />
            <DetailField
              label="Cycle End Date"
              value={
                client.wundamental?.cycle?.currentEndDate
                  ? new Date(
                      client.wundamental.cycle.currentEndDate
                    ).toLocaleDateString()
                  : null
              }
            />
          </Grid>
        </DetailSection>

        <DetailSection title="Remaining Resources">
          <Grid container spacing={2}>
            <DetailField
              label="Total Minutes"
              value={client.wundamental?.remaining?.minutes}
            />
            <DetailField
              label="Ad Hoc Minutes"
              value={client.wundamental?.remaining?.adHocMinutes}
            />
            <DetailField
              label="90-min Sessions"
              value={client.wundamental?.remaining?.sessions?.duration_90}
            />
            <DetailField
              label="60-min Sessions"
              value={client.wundamental?.remaining?.sessions?.duration_60}
            />
            <DetailField
              label="30-min Sessions"
              value={client.wundamental?.remaining?.sessions?.duration_30}
            />
            <DetailField
              label="15-min Sessions"
              value={client.wundamental?.remaining?.sessions?.duration_15}
            />
          </Grid>
        </DetailSection>

        <DetailSection title="Opt-ins">
          <Grid container spacing={2}>
            <DetailField
              label="Recording"
              value={
                client.wundamental?.optIns?.recording ? 'Enabled' : 'Disabled'
              }
            />
            <DetailField
              label="AI Insights"
              value={
                client.wundamental?.optIns?.aiInsights ? 'Enabled' : 'Disabled'
              }
            />
            <DetailField
              label="LCA"
              value={client.wundamental?.optIns?.lca ? 'Enabled' : 'Disabled'}
            />
            <DetailField
              label="Wheel of Life"
              value={
                client.wundamental?.optIns?.wheelOfLife ? 'Enabled' : 'Disabled'
              }
            />
            <DetailField
              label="Forward Plan"
              value={
                client.wundamental?.optIns?.forwardPlan ? 'Enabled' : 'Disabled'
              }
            />
          </Grid>
        </DetailSection>

        <DetailSection title="Metadata">
          <Grid container spacing={2}>
            <DetailField
              label="Created At"
              value={new Date(client.createdAt).toLocaleString()}
            />
            <DetailField
              label="Updated At"
              value={new Date(client.updatedAt).toLocaleString()}
            />
          </Grid>
        </DetailSection>
      </Stack>
    </Container>
  );
};

export default ClientDetailPage;
