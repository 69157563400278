import { Helmet } from 'react-helmet-async';
import { logEvent } from 'firebase/analytics';
import { styled, useTheme } from '@mui/material/styles';
import { Link, Container, Typography, Box, Alert } from '@mui/material';
import { useState, useContext, useRef, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getAuth,
  sendSignInLinkToEmail,
  GoogleAuthProvider,
} from 'firebase/auth';
import { analytics } from '../firebase/firebase';
import Iconify from '../components/iconify';
import {
  AuthContext,
  doesUserEmailExist,
  signInWithGoogleRedirect,
} from '../providers/AuthProvider';
import useResponsive from '../hooks/useResponsive';
import Logo from '../components/logo';
import GenericSnackbar from '../components/snackbar/GenericSnackbar';
import GenericLoader from '../components/loader/GenericLoader';
import ErrorMessage from '../components/alert/ErrorMessage';
import EmailSigninConfirm from '../components/wundamental/EmailSigninConfirm';
import WundaButton from '../components/wundamental/WundaButton';
// sections
import LoginForm from '../sections/auth/LoginForm';
import { logMessage } from '../utils/logger';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function LoginPage() {
  const auth = getAuth();
  const theme = useTheme();
  const { resetAuthInProgress, forceLogout, redirectResult, handleUserLogin } =
    useContext(AuthContext);
  const mdUp = useResponsive('up', 'md');
  const navigate = useNavigate();
  const [emailFormSubmitted, setEmailFormSubmitted] = useState(false);
  const [authError, setAuthError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userDataError, setUserDataError] = useState(null);
  const snackbarRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const navigateToSignup = useCallback(
    (user) => {
      logMessage(
        'DEBUG',
        `LoginPage.navigateToSignup(). Redirecting user to signup: ${user.email}`
      );
      navigate('/signup', {
        state: {
          email: user.email,
          fullName: user.displayName,
          firebaseUid: user.uid,
        },
      });
    },
    [navigate]
  );

  const handleError = (error, message) => {
    const errorMsg = `${message} ${error.message}`;
    logMessage('ERROR', `LoginPage.handleError(). ${errorMsg}`);
    setUserDataError(errorMsg);
  };

  useEffect(() => {
    const authenticateUser = async () => {
      const googleAuthInProgress = localStorage.getItem('googleAuthInProgress');
      if (googleAuthInProgress && redirectResult && redirectResult.user) {
        try {
          logMessage(
            'VERBOSE',
            `LoginPage.authenticateUser(). Starting Google authentication for user: ${redirectResult.user.email}`
          );
          setLoading(true);
          localStorage.setItem('googleAuthInProgress', 'true');
          await handleUserLogin(redirectResult.user);
          logEvent(analytics, 'login', { method: 'google' });
          logMessage(
            'VERBOSE',
            'LoginPage.authenticateUser(). Google authentication successful'
          );
          navigate('/home');
        } catch (error) {
          logMessage(
            'ERROR',
            `LoginPage.authenticateUser(). Authentication error: ${error.message}`
          );
          if (error.message === 'User not found') {
            logMessage(
              'VERBOSE',
              'LoginPage.authenticateUser(). User not found, redirecting to signup'
            );
            navigateToSignup(redirectResult.user);
          } else if (error.message === 'INVITED_USER') {
            logMessage(
              'VERBOSE',
              'LoginPage.authenticateUser(). Invited user detected, redirecting to signup'
            );
            navigateToSignup({
              ...redirectResult.user,
              isInvited: true,
            });
          } else {
            await forceLogout();
            handleError(error, 'Error signing in with Google!');
          }
        } finally {
          setLoading(false);
        }
      }
      resetAuthInProgress();
    };

    authenticateUser();
  }, [
    forceLogout,
    handleUserLogin,
    navigate,
    navigateToSignup,
    redirectResult,
    resetAuthInProgress,
  ]);

  const handleGetStartedClick = () => {
    navigate('/signup');
  };

  const showSuccess = (message) => {
    snackbarRef.current.showSnackbar({ message, severity: 'success' });
  };

  const handleGoogleLogin = async () => {
    logMessage(
      'VERBOSE',
      'LoginPage.handleGoogleLogin(). Starting Google login process'
    );
    setLoading(true);
    localStorage.setItem('googleAuthInProgress', 'true');
    try {
      await signInWithGoogleRedirect();
    } catch (error) {
      logMessage(
        'ERROR',
        `LoginPage.handleGoogleLogin(). Google login error: ${error.message}`
      );
      handleError(error, 'Error signing in with Google!');
      await forceLogout();
      const errorCode = error.code;
      const errorMessage = error.message;
      const credential = GoogleAuthProvider.credentialFromError(error);
      setAuthError(
        `Error signing in with Google (${credential})! ${errorCode} : ${errorMessage}`
      );
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async (email) => {
    logMessage(
      'VERBOSE',
      `LoginPage.handleLogin(). Starting login process for email: ${email}`
    );
    if (!email) {
      const errorMsg = 'Email is required.';
      logMessage('ERROR', `LoginPage.handleLogin(). ${errorMsg}`);
      setAuthError(errorMsg);
      return;
    }

    const actionCodeSettings = {
      url: `${window.location.origin}/auth/callback/`,
      handleCodeInApp: true,
    };

    try {
      logMessage(
        'VERBOSE',
        `LoginPage.handleLogin(). Checking user existence for email: ${email}`
      );
      const userStatus = await doesUserEmailExist(email);

      // If user is invited, redirect to signup
      if (userStatus.status === 'invited') {
        logMessage(
          'VERBOSE',
          `LoginPage.handleLogin(). Invited user detected: ${email}`
        );
        navigate('/signup', {
          state: {
            email,
            isInvited: true,
          },
        });
        return;
      }

      if (!userStatus.exists) {
        const errorMsg =
          'No account found with this email. Please sign up first.';
        logMessage('ERROR', `LoginPage.handleLogin(). ${errorMsg}`);
        throw new Error(errorMsg);
      }

      // User exists and is active, proceed with email link
      logMessage(
        'VERBOSE',
        `LoginPage.handleLogin(). Sending login email to: ${email}`
      );
      localStorage.setItem('emailAuthInProgress', 'true');
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      logEvent(analytics, 'login', { method: 'email' });
      logMessage(
        'VERBOSE',
        'LoginPage.handleLogin(). Login email sent successfully'
      );
      setEmailFormSubmitted(true);
    } catch (error) {
      const errorMsg = `Error signing in! ${error.response?.data?.message || error.message}`;
      logMessage('ERROR', `LoginPage.handleLogin(). ${errorMsg}`);
      setAuthError(errorMsg);
    }
  };

  if (loading) {
    return <GenericLoader label="Signing in..." />;
  }
  return (
    <>
      <Helmet>
        <title> Login | Wundamental </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img
              src="/assets/illustrations/illustration_login.png"
              alt="login"
            />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <ErrorMessage message={errorMessage} setMessage={setErrorMessage} />
          <StyledContent>
            {!emailFormSubmitted ? ( // Render the form if it's not submitted
              <>
                <Typography variant="h4" gutterBottom>
                  Sign in to Wundamental
                </Typography>

                <Typography variant="body2" sx={{ mb: 5 }}>
                  Don't have an account?{' '}
                  <Link
                    to="/signup"
                    component={Link}
                    onClick={handleGetStartedClick}
                    sx={{ cursor: 'pointer' }}
                  >
                    Sign up now
                  </Link>
                </Typography>

                {userDataError && (
                  <Alert severity="error">{userDataError}</Alert>
                )}
                <LoginForm onLogin={handleLogin} error={authError} />

                <Box
                  mt={2}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Typography variant="body1">or</Typography>
                  <Box mt={2}>
                    <WundaButton
                      startIcon={<Iconify icon="logos:google-icon" />}
                      variant="contained"
                      color="primary"
                      onClick={handleGoogleLogin}
                      size="large"
                      sx={{
                        backgroundColor: '#ffffff',
                        color: theme.palette.text.primary,
                        '&:hover': {
                          backgroundColor: '#f5f5f5',
                        },
                      }}
                    >
                      Sign in with Google
                    </WundaButton>
                  </Box>
                </Box>
              </>
            ) : (
              // Show success message if the form is submitted
              <>
                <EmailSigninConfirm
                  title="Check your inbox for the login link."
                  body="We have sent an email to your registered email address with a link to sign in."
                  onSuccess={() =>
                    showSuccess('Email sign-in link sent successfully')
                  }
                  onFailure={() =>
                    setErrorMessage(
                      'Failed to send e-mail link. Please try again later.'
                    )
                  }
                />
              </>
            )}
          </StyledContent>
        </Container>
      </StyledRoot>

      <GenericSnackbar ref={snackbarRef} />
    </>
  );
}
