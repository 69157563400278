import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Stack,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
} from '@mui/material';
import { Icon } from '@iconify/react';
import WundaButton from './WundaButton';

const OPERATORS = [
  { value: 'is', label: 'Is' },
  { value: 'isNot', label: 'Is not' },
  { value: 'contains', label: 'Contains' },
  { value: 'doesNotContain', label: 'Does not contain' },
  { value: 'startsWith', label: 'Starts with' },
  { value: 'endsWith', label: 'Ends with' },
  { value: 'isEmpty', label: 'Is empty' },
  { value: 'isNotEmpty', label: 'Is not empty' },
];

const WundaFilter = ({
  fields,
  onApply,
  onClear,
  activeFilters = [],
  renderTrigger,
}) => {
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    // Initialize filters from activeFilters if they exist
    if (activeFilters.length > 0) {
      setFilters(activeFilters);
    }
  }, [activeFilters]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddFilter = () => {
    setFilters([...filters, { field: '', operator: 'is', value: '' }]);
  };

  const handleRemoveFilter = (index) => {
    setFilters(filters.filter((_, i) => i !== index));
  };

  const handleFilterChange = (index, field, value) => {
    const newFilters = [...filters];
    newFilters[index] = { ...newFilters[index], [field]: value };
    setFilters(newFilters);
  };

  const handleApply = () => {
    const validFilters = filters.filter(
      (filter) =>
        filter.field &&
        filter.operator &&
        (filter.operator === 'isEmpty' ||
          filter.operator === 'isNotEmpty' ||
          filter.value !== '')
    );

    console.log('Applying filters:', validFilters);
    onApply(validFilters);
    handleClose();
  };

  const handleClear = () => {
    setFilters([]);
    onClear();
    handleClose();
  };

  const defaultTrigger = (
    <WundaButton
      variant="outlined"
      startIcon={<Icon icon="mdi:filter-variant" />}
      onClick={handleOpen}
    >
      Filters
    </WundaButton>
  );

  return (
    <>
      {renderTrigger ? renderTrigger({ onClick: handleOpen }) : defaultTrigger}

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Filter Clients</Typography>
            <IconButton onClick={handleClose}>
              <Icon icon="mdi:close" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 2 }}>
            {filters.map((filter, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  gap: 1,
                  alignItems: 'flex-start',
                }}
              >
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel>Field</InputLabel>
                  <Select
                    value={filter.field}
                    label="Field"
                    onChange={(e) =>
                      handleFilterChange(index, 'field', e.target.value)
                    }
                  >
                    {fields.map((field) => (
                      <MenuItem key={field.id} value={field.id}>
                        {field.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ minWidth: 150 }}>
                  <InputLabel>Operator</InputLabel>
                  <Select
                    value={filter.operator}
                    label="Operator"
                    onChange={(e) =>
                      handleFilterChange(index, 'operator', e.target.value)
                    }
                  >
                    {OPERATORS.map((operator) => (
                      <MenuItem key={operator.value} value={operator.value}>
                        {operator.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {filter.operator !== 'isEmpty' &&
                  filter.operator !== 'isNotEmpty' && (
                    <TextField
                      label="Value"
                      value={filter.value}
                      onChange={(e) =>
                        handleFilterChange(index, 'value', e.target.value)
                      }
                      sx={{ flex: 1 }}
                    />
                  )}

                <IconButton
                  onClick={() => handleRemoveFilter(index)}
                  color="error"
                  size="small"
                >
                  <Icon icon="mdi:delete" />
                </IconButton>
              </Box>
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={1}>
            <Button onClick={handleClear}>Clear All</Button>
            <WundaButton
              onClick={handleAddFilter}
              startIcon={<Icon icon="mdi:plus" />}
            >
              Add Filter
            </WundaButton>
            <WundaButton onClick={handleApply} variant="contained">
              Apply Filters
            </WundaButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};

WundaFilter.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  onApply: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  activeFilters: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      operator: PropTypes.string.isRequired,
      value: PropTypes.string,
    })
  ),
  renderTrigger: PropTypes.func,
};

export default WundaFilter;
